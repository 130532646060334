import { Platform } from 'react-native';

/* Production */

const configApiUrl = () => {
    // Backend-Url
    return 'https://api.kv.orgaplan-beratung.de';
};

/* Local */

// const configApiUrl = () => {
//     if (Platform.OS === 'android') {
//         return 'http://10.0.2.2:8080';
//     } else if (Platform.OS === 'ios' || Platform.OS !== 'android') {
//         return 'http://localhost:8080';
//     }
// };

const API_URL = configApiUrl();

export { API_URL };