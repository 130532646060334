import React, {useState, useEffect, useContext} from 'react';
import {View, Text, Switch, StyleSheet, Alert, ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Button from '../../Button';
import {fetchRoles, registerNewClient} from "../../../api/endpoint";
import {UserContext}  from "../../../context/UserContext";
import {useSnackbar} from "../../../context/SnackbarContext";
import CompanySection from "./CompanySection";
import PersonalInfoSection from "./PersonalInfoSection";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F6F6F6',
        paddingHorizontal: 16,
        paddingTop: 16,
    },
    content: {
        flex: 1,
        justifyContent: 'flex-start',
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
        marginBottom: 20,
        color: '#333',
    },
    switchContainerWrapper: {
        width: '100%',
        alignItems: 'flex-start',
        marginBottom: 20,
    },
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    switchText: {
        fontSize: 16,
        color: '#555',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
    registerButton: {
        backgroundColor: '#4A90E2',
        paddingVertical: 14,
        paddingHorizontal: 24,
        borderRadius: 7,
        width: '60%',
        alignItems: 'center',
    },
    registerButtonText: {
        color: '#fff',
        fontSize: 18,
        fontWeight: 'bold',
    },
});

const Registrierung = () => {
    const { user } = useContext(UserContext);
    const [isCompany, setIsCompany] = useState(false);
    const [vermittlerId, setVermittlerId] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [industry, setIndustry] = useState('');
    const [numberOfEmployees, setNumberOfEmployees] = useState('');
    const [ceo, setCeo] = useState('');
    const [court, setCourt] = useState('');
    const [commercialRegisterNumber, setCommercialRegisterNumber] = useState('');
    const [vatId, setVatId] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [fax, setFax] = useState('');
    const [website, setWebsite] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
    const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false);
    const [availableRoles, setAvailableRoles] = useState([]);

    const navigation = useNavigation();
    const { showSnackbar } = useSnackbar();

    const companyInputFields = [
        {
            placeholder: "Firmenname",
            value: companyName,
            onChangeText: setCompanyName,
            secureTextEntry: false,
            required: true
        },
        {
            placeholder: "Straßennummer",
            value: streetNumber,
            onChangeText: setStreetNumber,
            secureTextEntry: false,
            required: true
        },
        {
            placeholder: "Postleitzahl",
            value: postalCode,
            onChangeText: setPostalCode,
            secureTextEntry: false,
            required: true
        },
        {
            placeholder: "Stadt",
            value: city,
            onChangeText: setCity,
            secureTextEntry: false,
            required: true
        },
        {
            placeholder: "Land",
            value: country,
            onChangeText: setCountry,
            secureTextEntry: false,
            required: true
        },
        {
            placeholder: "Branche",
            value: industry,
            onChangeText: setIndustry,
            secureTextEntry: false
        },
        {
            placeholder: "Anzahl der Mitarbeiter",
            value: numberOfEmployees,
            onChangeText: setNumberOfEmployees,
            secureTextEntry: false
        },
        {
            placeholder: "Geschäftsführer",
            value: ceo,
            onChangeText: setCeo,
            secureTextEntry: false
        },
        {
            placeholder: "Gericht",
            value: court,
            onChangeText: setCourt,
            secureTextEntry: false
        },
        {
            placeholder: "Handelsregisternummer",
            value: commercialRegisterNumber,
            onChangeText: setCommercialRegisterNumber,
            secureTextEntry: false
        },
        {
            placeholder: "Umsatzsteuer-Identifikationsnummer",
            value: vatId,
            onChangeText: setVatId,
            secureTextEntry: false
        },
        {
            placeholder: "E-Mail",
            value: companyEmail,
            onChangeText: setCompanyEmail,
            secureTextEntry: false
        },
        {
            placeholder: "Telefon",
            value: phone,
            onChangeText: setPhone,
            secureTextEntry: false
        },
        {
            placeholder: "Fax",
            value: fax,
            onChangeText: setFax,
            secureTextEntry: false
        },
        {
            placeholder: "Website",
            value: website,
            onChangeText: setWebsite,
            secureTextEntry: false
        },
    ];

    const personInputFields = [
        {
            placeholder: "Vorname",
            value: firstName,
            onChangeText: setFirstName,
            secureTextEntry: false,
            required: true
        },
        {
            placeholder: "Nachname",
            value: lastName,
            onChangeText: setLastName,
            secureTextEntry: false,
            required: true
        },
        {
            placeholder: "Telefonnummer",
            value: phoneNumber,
            onChangeText: setPhoneNumber,
            secureTextEntry: false,
            required: true
        },
        {
            placeholder: "E-Mail",
            value: email,
            onChangeText: setEmail,
            secureTextEntry: false,
            required: true
        },
        {
            placeholder: "Passwort",
            value: password,
            onChangeText: setPassword,
            secureTextEntry: true,
            required: true
        },
        {
            placeholder: "Passwort bestätigen",
            value: confirmPassword,
            onChangeText: setConfirmPassword,
            secureTextEntry: true,
            required: true
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            setVermittlerId(user.id);

            const roles = await fetchRoles();
            setAvailableRoles(roles);
        };

        fetchData();
    }, []);

    const handleRegister = async () => {
        if (
            !firstName ||
            !lastName ||
            !phoneNumber ||
            !email ||
            !password ||
            !confirmPassword ||
            !privacyPolicyAccepted ||
            !termsAndConditionsAccepted ||
            (isCompany && (
                !companyName ||
                !streetNumber ||
                !postalCode ||
                !city ||
                !country
            ))
        ) {
            Alert.alert('Warnung', 'Bitte füllen Sie alle Felder aus!');
            return;
        }

        if (password !== confirmPassword) {
            Alert.alert('Warnung', 'Das Passwort und das bestätigte Passwort stimmen nicht überein!');
            return;
        }

        try {
            const role = isCompany ? availableRoles.find(r => r === 'FIRMEN_KUNDEN') : availableRoles.find(r => r === 'PRIVAT_KUNDEN');
            const userData = {
                vermittlerId,
                companyName: isCompany ? companyName : '',
                streetNumber: isCompany ? streetNumber : '',
                postalCode: isCompany ? postalCode : '',
                city: isCompany ? city : '',
                country: isCompany ? country : '',
                industry: isCompany ? industry : '',
                numberOfEmployees: isCompany ? numberOfEmployees : '',
                ceo: isCompany ? ceo : '',
                court: isCompany ? court : '',
                commercialRegisterNumber: isCompany ? commercialRegisterNumber : '',
                vatId: isCompany ? vatId : '',
                companyEmail: isCompany ? companyEmail : '',
                phone: isCompany ? phone : '',
                fax: isCompany ? fax : '',
                website: isCompany ? website : '',
                firstName,
                lastName,
                phoneNumber,
                email,
                password,
                role,
                privacyPolicyAccepted,
                termsAndConditionsAccepted,
            };

            await registerNewClient(userData);
            showSnackbar('Kunde erfolgreich erstellt', 'success');
            navigation.navigate('Kundenverwaltung');
        } catch (error) {
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Ein unerwarteter Fehler ist aufgetreten.';
            showSnackbar(errorMessage, 'error');
        }
    };

    return (
        <ScrollView contentContainerStyle={{ flexGrow: 1, paddingBottom: 60 }}>
            <View style={styles.container}>
                <View style={styles.content}>
                    <Text style={styles.title}>Registrierung für Kunden</Text>

                    <View style={styles.switchContainerWrapper}>
                        <View style={{ ...styles.switchContainer }}>
                            <Switch value={isCompany} onValueChange={setIsCompany} style={{ marginRight: 10 }} />
                            <Text style={styles.switchText}>Ist der Kunde ein Firmenkunde?</Text>
                        </View>
                    </View>

                    {isCompany && (
                        <CompanySection
                            inputFields={companyInputFields}
                        />
                    )}

                    <PersonalInfoSection
                        inputFields={personInputFields}
                        privacyPolicyAccepted={privacyPolicyAccepted}
                        setPrivacyPolicyAccepted={setPrivacyPolicyAccepted}
                        termsAndConditionsAccepted={termsAndConditionsAccepted}
                        setTermsAndConditionsAccepted={setTermsAndConditionsAccepted}
                    />

                    <View style={styles.buttonContainer}>
                        <Button
                            title="Registrieren"
                            onPress={handleRegister}
                            buttonStyle={styles.registerButton}
                            textStyle={styles.registerButtonText}
                        />
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

export default Registrierung;
