import React from 'react';
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';
import * as Progress from 'react-native-progress';
import {Ionicons} from "@expo/vector-icons";
import {formatDate} from "../../../utils/format";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
    },
    progressBox: {
        padding: 10,
        borderRadius: 10,
        width: '97%',
        alignItems: 'center',
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#333',
    },
    status: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginVertical: 5,
    },
    progressText: {
        color: '#333',
        fontSize: 16,
    },
    squareFrame: {
        marginLeft: 10,
        width: 200,
        height: 100,
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: '#d1d1d1',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleText: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#555',
        marginBottom: 8,
    },
    statusText: {
        fontSize: 12,
        fontWeight: '600',
        color: '#333',
        padding: 4,
    },
    refreshIcon: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        borderRadius: 25,
        backgroundColor: 'white',
        marginLeft: 10,
    },
});

const DocumentProgress = ({ percentageUploaded, user, fetchDocument, updateUser }) => {
    const progress = percentageUploaded / 100;
    const color = progress < 0.5
        ? `rgb(${255}, ${Math.round(255 * (2 * progress))}, 0)`
        : progress < 1
            ? `rgb(${Math.round(255 * (2 - 2 * progress))}, 255, 0)`
            : '#00FF00';

    const lastUpdateText = user.forwardedBanksAt
        ? `Weitergeleitet am: ${formatDate(user.forwardedBanksAt)}`
        : '';

    return (
        <View style={styles.container}>
            <View style={styles.progressBox}>
                <Text style={styles.title}>Fortschritt beim Hochladen</Text>
                <View style={styles.status}>
                    <Progress.Circle
                        progress={progress}
                        size={100}
                        thickness={15}
                        color={color}
                        unfilledColor="#EDEDED"
                        borderWidth={0}
                        showsText={true}
                        formatText={p => `${percentageUploaded} %`}
                        textStyle={styles.progressText}
                    />
                    <View style={styles.squareFrame}>
                        <Text style={styles.titleText}>Banken Weiterleitung</Text>
                        <Text style={styles.statusText}>{user.forwardedBanks ? 'Ja' : 'Nein'}</Text>
                        <Text style={styles.statusText}>{lastUpdateText}</Text>
                    </View>
                    <TouchableOpacity
                        style={styles.refreshIcon}
                        onPress={async () => {
                            try {
                                await fetchDocument();
                                await updateUser();
                            } catch (error) {
                                console.error('An error occurred:', error);
                            }
                        }}>
                        <Ionicons name="refresh" size={24} color="#888" />
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
}

export default DocumentProgress;