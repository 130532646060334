import React, {useState, useContext} from 'react';
import {SafeAreaView, View, FlatList, StyleSheet, TouchableOpacity, TextInput, Alert} from "react-native";
import { Ionicons } from '@expo/vector-icons';
import KundenCard from './KundenCard';
import { getClients } from "../../../api/endpoint";
import {UserContext}  from "../../../context/UserContext";
import Pagination from "./Pagination";
import {useFocusEffect} from "@react-navigation/native";
import FilterOptions from "./FilterOptions";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F2F2F2',
    },
    searchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 20,
        marginVertical: 12
    },
    search: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#F5F5F5',
        padding: 12,
        borderRadius: 25,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,
        elevation: 6,
        flex: 1
    },
    input: {
        flex: 1,
        borderColor: '#E0E0E0',
        borderWidth: 0,
        fontSize: 16,
    },
    iconContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        width: '50%',
    },
});

const ITEMS_PER_PAGE = 10;

const Kundenverwaltung = () => {
    const { user } = useContext(UserContext);
    const vermittlerId = user.id;
    const [searchTerm, setSearchTerm] = useState("");
    const [clients, setClients] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(clients.length / ITEMS_PER_PAGE);

    const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
    const [filterOptions, setFilterOptions] = useState({
        role: null,
        active: null,
        kreditanfrage: null,
        forwardedBanks: null,
        documentProgress: null
    });

    const toggleFilterModal = () => {
        setIsFilterModalVisible(!isFilterModalVisible);
    };

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    const applyFilters = (updatedFilters) => {
        setFilterOptions(updatedFilters);
        setIsFilterModalVisible(false);
    };

    const previewFilteredResults = (filterOptions) => {
        return clients.filter(client => (
            (filterOptions.role === null || client.role === filterOptions.role) &&
            (filterOptions.active === null || client.active === filterOptions.active) &&
            (filterOptions.kreditanfrage === null || client.kreditanfrage === filterOptions.kreditanfrage) &&
            (filterOptions.forwardedBanks === null || client.forwardedBanks === filterOptions.forwardedBanks) &&
            (filterOptions.documentProgress === null || client.documentProgress <= filterOptions.documentProgress)
        )).length;
    };

    const filterClients = () => {
        let results = clients;

        if (searchTerm) {
            const lowerCaseSearchTerm = searchTerm.toLowerCase();
            results = results.filter(client =>
                client.firstName.toLowerCase().includes(lowerCaseSearchTerm) ||
                client.lastName.toLowerCase().includes(lowerCaseSearchTerm) ||
                client.email.toLowerCase().includes(lowerCaseSearchTerm)
            );
        }

        if (filterOptions.role !== null) {
            results = results.filter(client => client.role === filterOptions.role);
        }

        if (filterOptions.active !== null) {
            results = results.filter(client => client.active === filterOptions.active);
        }

        if (filterOptions.kreditanfrage !== null) {
            results = results.filter(client => client.kreditanfrage === filterOptions.kreditanfrage);
        }

        if (filterOptions.forwardedBanks !== null) {
            results = results.filter(client => client.forwardedBanks === filterOptions.forwardedBanks);
        }

        if (filterOptions.documentProgress !== null) {
            results = results.filter(client =>
                client.documentProgress >= 0 &&
                client.documentProgress <= filterOptions.documentProgress
            );
        }

        return results;
    };

    const resetClients = async () => {
        setSearchTerm("");
        setFilterOptions({
            role: null,
            active: null,
            kreditanfrage: null,
            forwardedBanks: null,
            documentProgress: null
        });
        await fetchData();
    };

    const filteredClients = filterClients();

    const displayClients = filteredClients.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const fetchData = async () => {
        try {
            const clientsData = await getClients(vermittlerId);
            setClients(clientsData);
        } catch (error) {
            console.error('Failed to fetch clients data:', error);
            Alert.alert('Error', 'Failed to fetch clients data.');
        }
    };

    useFocusEffect(
        React.useCallback(() => {
            fetchData();
        }, [])
    );

    const renderUserItem = ({ item }) => (
        <KundenCard
            client={item}
            vermittlerId={vermittlerId}
        />
    );

    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.searchContainer}>
                <View style={styles.search}>
                    <Ionicons name="search" size={24} color="#888" style={{ marginRight: 10 }} />
                    <TextInput
                        style={styles.input}
                        placeholder="Suchen..."
                        value={searchTerm}
                        onChangeText={handleSearch}
                        autoCapitalize="none"
                    />
                </View>
            </View>

            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <View style={styles.iconContainer}>
                    <TouchableOpacity onPress={resetClients}>
                        <Ionicons name="list-circle-sharp" size={36} color="#6c757d" />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={toggleFilterModal}>
                        <Ionicons name="options-outline" size={36} color="#34A853" />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={fetchData}>
                        <Ionicons name="refresh-circle" size={36} color="#007bff" />
                    </TouchableOpacity>
                </View>

                <FilterOptions
                    isFilterModalVisible={isFilterModalVisible}
                    toggleFilterModal={toggleFilterModal}
                    filterOptions={filterOptions}
                    previewFilteredResults={previewFilteredResults}
                    onApply={applyFilters}
                />

                <FlatList
                    data={displayClients}
                    keyExtractor={(item) => item.id.toString()}
                    renderItem={renderUserItem}
                    numColumns={1}
                />

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                />
            </View>
        </SafeAreaView>
    );
};

export default Kundenverwaltung;
