import React from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    title: {
        fontSize: 22,
        fontWeight: '600',
        marginBottom: 16,
        color: '#444',
    },
    input: {
        width: '100%',
        height: 48,
        borderColor: '#CDCDCD',
        borderWidth: 1,
        borderRadius: 7,
        marginBottom: 20,
        paddingHorizontal: 14,
        fontSize: 16,
        backgroundColor: '#FFF',
    }
});

const CompanySection = ({ inputFields }) => {
    return (
        <View>
            <Text style={{ ...styles.title, fontSize: 20 }}>Firmeninformation</Text>
            {inputFields.map((field, index) => (
                <TextInput
                    key={index}
                    style={styles.input}
                    placeholder={`${field.required ? `${field.placeholder} (Pflichtfelder)` : field.placeholder}`}
                    value={field.value}
                    onChangeText={field.onChangeText}
                    secureTextEntry={field.secureTextEntry}
                    autoCapitalize="none"
                />
            ))}
        </View>
    );
};

export default CompanySection;
