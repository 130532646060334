import React, { useEffect, useState } from 'react';
import { Modal, TouchableOpacity, View, Text, StyleSheet, Switch } from 'react-native';
import Slider from "@react-native-community/slider";

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalView: {
        marginVertical: '10%',
        marginHorizontal: '5%',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 15,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 6,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15,
    },
    headerButton: {
        color: '#1B73E8',
        fontSize: 16,
        fontWeight: '600',
    },
    headerTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    switchContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 8,
    },
    switchLabel: {
        fontSize: 16,
    },
    showResultsButton: {
        backgroundColor: '#007bff',
        borderRadius: 10,
        paddingVertical: 10,
        paddingHorizontal: 20,
        alignItems: 'center',
        marginTop: 20,
    },
    showResultsButtonText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
    categoryContainer: {
        backgroundColor: '#F5F5F5',
        borderRadius: 10,
        padding: 10,
        marginVertical: 8,
    },
    categoryTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    sliderContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
    },
    percentageText: {
        position: 'absolute',
        alignSelf: 'center',
        top: '100%',
        fontSize: 16,
        fontWeight: 'bold',
        color: '#000',
    },
    roleFilterContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: 8,
    },
    roleButton: {
        padding: 10,
        borderWidth: 1,
        borderColor: '#007bff',
        borderRadius: 5,
        marginHorizontal: 4,
        backgroundColor: 'white',
    },
    selectedRole: {
        backgroundColor: '#007bff',
    },
    roleButtonText: {
        color: '#007bff',
        textAlign: 'center',
    },
    roleButtonTextSelected: {
        color: 'white',
    },
});

const FilterCategory = ({ title, children }) => (
    <View style={styles.categoryContainer}>
        <Text style={styles.categoryTitle}>{title}</Text>
        {children}
    </View>
);

const UserRoleFilter = ({ selectedRole, onSelect }) => {
    return (
        <View style={styles.roleFilterContainer}>
            <TouchableOpacity
                style={[styles.roleButton, selectedRole === 'PRIVAT_KUNDEN' && styles.selectedRole]}
                onPress={() => onSelect('PRIVAT_KUNDEN')}
            >
                <Text style={[styles.roleButtonText, selectedRole === 'PRIVAT_KUNDEN' && styles.roleButtonTextSelected]}>
                    Privatkunden
                </Text>
            </TouchableOpacity>

            <TouchableOpacity
                style={[styles.roleButton, selectedRole === 'FIRMEN_KUNDEN' && styles.selectedRole]}
                onPress={() => onSelect('FIRMEN_KUNDEN')}
            >
                <Text style={[styles.roleButtonText, selectedRole === 'FIRMEN_KUNDEN' && styles.roleButtonTextSelected]}>
                    Firmenkunden
                </Text>
            </TouchableOpacity>
        </View>
    );
};

const FilterOptions = ({ isFilterModalVisible, toggleFilterModal, onApply, previewFilteredResults }) => {
    const initialFilterOptions = {
        role: "PRIVAT_KUNDEN",
        active: false,
        kreditanfrage: false,
        forwardedBanks: false,
        documentProgress: 0.0
    };
    const [localFilterOptions, setLocalFilterOptions] = useState(initialFilterOptions);
    const [filteredResultsCount, setFilteredResultsCount] = useState(0);

    useEffect(() => {
        const count = previewFilteredResults(localFilterOptions);
        setFilteredResultsCount(count);
    }, [localFilterOptions, previewFilteredResults]);

    const resetFilters = () => {
        setLocalFilterOptions(initialFilterOptions);
    };

    const applyFilters = () => {
        onApply(localFilterOptions);
        toggleFilterModal();
    };

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={isFilterModalVisible}
            onRequestClose={toggleFilterModal}
        >
            <View style={styles.centeredView}>
                <View style={styles.modalView}>

                    {/* Header */}
                    <View style={styles.header}>
                        <TouchableOpacity onPress={toggleFilterModal}>
                            <Text style={styles.headerButton}>Abbrechen</Text>
                        </TouchableOpacity>
                        <Text style={styles.headerTitle}>Kundenfilter</Text>
                        <TouchableOpacity onPress={resetFilters}>
                            <Text style={styles.headerButton}>Zurücksetzen</Text>
                        </TouchableOpacity>
                    </View>

                    <FilterCategory title="Kundenrolle">
                        <UserRoleFilter
                            selectedRole={localFilterOptions.role}
                            onSelect={(value) => {
                                setLocalFilterOptions(prevOptions => ({ ...prevOptions, role: value }));
                            }}
                        />
                    </FilterCategory>

                    <FilterCategory title="Interaktionen">

                        {/* Active filter */}
                        <View style={styles.switchContainer}>
                            <Text style={styles.switchLabel}>Aktive Kunden</Text>
                            <Switch
                                value={localFilterOptions.active === true}
                                onValueChange={(newValue) =>
                                    setLocalFilterOptions({ ...localFilterOptions, active: newValue })
                                }
                            />
                        </View>

                        {/* Kreditanfrage filter */}
                        <View style={styles.switchContainer}>
                            <Text style={styles.switchLabel}>Hat Kreditanfrage</Text>
                            <Switch
                                value={localFilterOptions.kreditanfrage === true}
                                onValueChange={(newValue) =>
                                    setLocalFilterOptions({ ...localFilterOptions, kreditanfrage: newValue })
                                }
                            />
                        </View>

                        {/* Banken Weiterleitung filter */}
                        <View style={styles.switchContainer}>
                            <Text style={styles.switchLabel}>Banken Weiterleitung</Text>
                            <Switch
                                value={localFilterOptions.forwardedBanks === true}
                                onValueChange={(newValue) =>
                                    setLocalFilterOptions({ ...localFilterOptions, forwardedBanks: newValue })
                                }
                            />
                        </View>

                    </FilterCategory>

                    <FilterCategory title="Dokumentenfortschritt">
                        <View style={styles.sliderContainer}>
                            <Slider
                                style={{ width: '100%' }}
                                minimumValue={0}
                                maximumValue={100}
                                thumbTintColor="#007bff"
                                step={1}
                                value={localFilterOptions.documentProgress || 0}
                                onValueChange={(value) => {
                                    setLocalFilterOptions({ ...localFilterOptions, documentProgress: value });
                                }}
                            />
                            <Text style={styles.percentageText}>
                                {localFilterOptions.documentProgress || 0}%
                            </Text>
                        </View>
                    </FilterCategory>

                    {/* Apply button */}
                    <TouchableOpacity style={styles.showResultsButton} onPress={applyFilters}>
                        <Text style={styles.showResultsButtonText}>
                            Zeige {filteredResultsCount} Ergebnisse
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    );
};

export default FilterOptions;