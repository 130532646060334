import React, { useState, useEffect, useContext } from 'react';
import { Alert, View, ActivityIndicator, SafeAreaView } from 'react-native';
import { Ionicons } from 'react-native-vector-icons';
import Kreditantragsformular from './Kreditantragsformular';
import KreditAnzeigen from './KreditAnzeigen';
import { getCreditRequests } from "../../../api/endpoint";
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import useUserPolling from "../../../hooks/useUserPolling";

const Tab = createBottomTabNavigator();

const screens = [
    {
        name: 'Kreditantragsformular',
        component: Kreditantragsformular,
        title: 'Kreditantrag stellen',
        icon: (color, size, focused) => focused ? 'create' : 'create-outline'
    },
    {
        name: 'KreditAnzeigen',
        component: KreditAnzeigen,
        title: 'Kreditanzeigen',
        icon: (color, size, focused) => focused ? 'list' : 'list-outline'
    }
];

const Kreditanfrage = () => {
    const user = useUserPolling();
    const [initialTab, setInitialTab] = useState(null);

    useEffect(() => {
        const checkCreditRequests = async () => {
            try {
                const requests = await getCreditRequests(user.id);
                if (!requests || requests.length === 0) {
                    setInitialTab("Kreditantragsformular");
                } else {
                    setInitialTab("KreditAnzeigen");
                }
            } catch (error) {
                console.error("Error fetching data", error);
                Alert.alert("Verbindungsfehler",
                    "Es scheint ein Problem mit Ihrer Internetverbindung zu geben oder der Server antwortet nicht. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut."
                );
                setInitialTab("Kreditantragsformular");
            }
        };

        checkCreditRequests();
    }, []);

    const handleTabPress = (routeName) => {
        if (user.forwardedBanks && routeName === 'Kreditantragsformular') {
            Alert.alert('Eingeschränkter Zugriff', 'Sie können keinen neuen Kreidtantrag stellen, da von ihrem Kreditvermittler die Bankenweiterleitung aktiviert wurde.');
            return false;
        }
        return true;
    }

    if (!initialTab) {
        return (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" />
            </View>
        );
    }

    return (
        <Tab.Navigator
            initialRouteName={initialTab}
            screenOptions={{
                unmountOnBlur: true,
                tabBarActiveTintColor: 'blue',
                tabBarInactiveTintColor: 'gray',
                tabBarStyle: {
                    backgroundColor: 'white',
                    position: 'absolute',
                    top: 0
                },
                tabBarLabelStyle: {
                    textTransform: 'none'
                },
            }}
        >
            {screens.map((screen) => (
                <Tab.Screen
                    key={screen.name}
                    name={screen.name}
                    component={screen.component}
                    options={{
                        title: screen.title || screen.name,
                        tabBarIcon: ({ color, size, focused }) => (
                            <Ionicons
                                name={screen.icon(color, size, focused)}
                                color={color}
                                size={26}
                            />
                        )
                    }}
                    listeners={({ route }) => ({
                        tabPress: e => {
                            if (!handleTabPress(route.name)) {
                                e.preventDefault();
                            }
                        }
                    })}
                />
            ))}
        </Tab.Navigator>
    );
};

export default Kreditanfrage;