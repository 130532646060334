import React, {useContext} from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import {UserContext} from "../context/UserContext";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
        backgroundColor: '#f4f4f4',
    },
    menuItem: {
        paddingVertical: 15,
        paddingHorizontal: 20,
        borderRadius: 10,
        backgroundColor: '#fff',
        marginVertical: 10,
        flexDirection: 'row',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 5,
    },
    menuText: {
        fontSize: 18,
        fontWeight: '600',
        color: '#333',
        flex: 1,
        marginLeft: 15,
    },
});

const MenuItem = ({ iconName, text, onPress }) => (
    <TouchableOpacity style={styles.menuItem} onPress={onPress}>
        <Ionicons name={iconName} size={26} color="#3498db" />
        <Text style={styles.menuText}>{text}</Text>
        <Ionicons name="arrow-forward-outline" size={20} color="#bdc3c7" />
    </TouchableOpacity>
);

const Setting = () => {
    const { user } = useContext(UserContext);
    const navigation = useNavigation();

    return (
        <View style={styles.container}>
            <MenuItem iconName="person-circle-outline" text="Konto" onPress={() => navigation.navigate('Account')} />
            {user && user.role === 'FIRMEN_KUNDEN' && (
                <MenuItem iconName="business-outline" text="Firmendaten" onPress={() => navigation.navigate('Firmendaten')} />
            )}
            <MenuItem iconName="book-outline" text="Impressum" onPress={() => Linking.openURL('https://orgaplan-beratung.de/impressum')} />
            <MenuItem iconName="shield-checkmark-outline" text="Datenschutz" onPress={() => Linking.openURL('https://orgaplan-beratung.de/datenschutz')} />
        </View>
    );
};

export default Setting;
