import React from 'react';
import { View, Text, TouchableOpacity, Modal, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.4)',
    },
    modalView: {
        width: 280,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 15,
        padding: 25,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3,
        elevation: 5,
    },
    text: {
        fontSize: 18,
        textAlign: 'center',
        color: '#333333',
        marginBottom: 20,
    },
    button: {
        padding: 10,
        width: '45%',
        alignItems: 'center',
        borderRadius: 10,
    },
    yesButton: {
        backgroundColor: '#4CAF50',
    },
    noButton: {
        backgroundColor: '#007AFF',
    },
    buttonText: {
        fontSize: 16,
        color: '#ffffff',
    },
});

const DeleteConfirmationModal = ({ visible, onConfirm, onCancel }) => (
    <Modal
        animationType="fade"
        transparent={true}
        visible={visible}
    >
        <View style={styles.modalContainer}>
            <View style={styles.modalView}>
                <Text style={styles.text}>Sind Sie sicher, dass Sie diese Datei löschen möchten?</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                    <TouchableOpacity style={[styles.button, styles.yesButton]} onPress={onConfirm}>
                        <Text style={styles.buttonText}>Ja</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.button, styles.noButton]} onPress={onCancel}>
                        <Text style={styles.buttonText}>Nein</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    </Modal>
);

export default DeleteConfirmationModal;
