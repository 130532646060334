import React from 'react';
import { Snackbar } from 'react-native-paper';
import { View, Text, Animated, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import {useSnackbar} from "../context/SnackbarContext";

const SnackBarMessenge = () => {
    const {
        visible,
        setVisible,
        message,
        icon,
        title,
        snackbarStyle,
        duration,
    } = useSnackbar();

    const progress = new Animated.Value(0);
    Animated.timing(progress, {
        toValue: 1,
        duration: duration || 1500,
        useNativeDriver: false,
    }).start();

    const progressBarStyle = {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: progress.interpolate({
            inputRange: [0, 1],
            outputRange: ['100%', '0%'],
        }),
        height: 3,
        backgroundColor: 'rgba(255,255,255,0.8)'
    };

    return (
        <Snackbar
            visible={visible}
            onDismiss={() => setVisible(false)}
            wrapperStyle={{ top: 0 }}
            style={[{ borderRadius: 8, elevation: 5 }, snackbarStyle]}
            duration={duration}
        >
            <View style={{ flexDirection: 'row', alignItems: 'center', padding: 8, flexWrap: 'wrap' }}>
                <View style={{
                    marginRight: 10,
                    backgroundColor: 'white',
                    borderRadius: 50,
                    elevation: 5,
                    shadowColor: "#000",
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    borderWidth: 2,
                    borderColor: '#E0E0E0',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Ionicons
                        name={icon}
                        size={24}
                        color="#000"
                    />
                </View>
                <Text style={{ fontWeight: 'bold', marginRight: 10, fontSize: 16 }}>{title}:</Text>
                <View style={{ flex: 1 }}>
                    <Text style={{ fontSize: 14 }}>{message}</Text>
                </View>
                <TouchableOpacity onPress={() => setVisible(false)}>
                    <Ionicons name="close" size={24} color="#000" />
                </TouchableOpacity>
            </View>
            <View style={{ marginTop: 10 }}>
                <Animated.View style={progressBarStyle} />
            </View>
        </Snackbar>
    );
};

export default SnackBarMessenge;