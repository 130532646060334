import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {StyleSheet, View} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import Home from "../components/kunden/home/Home";
import Document from '../components/kunden/document/Document';
import Kreditanfrage from "../components/kunden/request/Kreditanfrage";
import UserDropdown from "./UserDropdown";
import Setting from "../components/Setting";

const Tab = createBottomTabNavigator();

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F2F2F2',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        color: '#333',
    },
    tabBarContainer: {
        flex: 1,
    },
    modalView: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        backgroundColor: 'white',
        padding: 15,
        alignItems: 'center',
    }
});

const screens = [
    {
        name: 'Home',
        component: Home,
        icon: (color, size, focused) => focused ? 'md-home' : 'md-home-outline'
    },
    {
        name: 'DokumentTab',
        component: Document,
        title: 'Dokumente',
        icon: (color, size, focused) => focused ? 'md-document' : 'md-document-outline'
    },
    {
        name: 'KreditanfrageTab',
        component: Kreditanfrage,
        title: 'Kreditanfrage',
        icon: (color, size, focused) => focused ? 'md-card' : 'md-card-outline'
    },
    {
        name: 'Setting',
        component: Setting,
        title: 'Einstellung',
        icon: (color, size, focused) => focused ? 'settings' : 'settings-outline'
    }
];

const ClientTabNavigator = () => {
    return (
            <View style={styles.tabBarContainer}>
                <Tab.Navigator
                    screenOptions={{
                        unmountOnBlur: true,
                        headerShown: true,
                        headerStyle: {
                            backgroundColor: '#3498db',
                            elevation: 0,
                            shadowOpacity: 0,
                            borderBottomWidth: 0.5,
                            borderBottomColor: 'gray',
                        },
                        headerTintColor: 'white',
                        headerTitleStyle: {
                            fontWeight: '600',
                            fontSize: 18,
                            marginLeft: 10
                        },
                        headerTitleAlign: 'center',
                        headerRight: () => (
                            <View style={{ marginRight: 20, paddingVertical: 8 }}>
                                <UserDropdown />
                            </View>
                        ),
                        tabBarActiveTintColor: '#3498db',
                        tabBarInactiveTintColor: 'gray',
                        tabBarLabelStyle: {
                            fontSize: 10,
                            fontWeight: '600',
                        },
                        tabBarStyle: [
                            {
                                display: 'flex',
                                backgroundColor: 'white',
                                borderTopWidth: 0.5,
                                borderTopColor: 'gray',
                                paddingBottom: 5,
                                paddingTop: 5,
                                height: 60,
                            }
                        ]
                    }}
                >
                    {screens.map((screen) => (
                        <Tab.Screen
                            key={screen.name}
                            name={screen.name}
                            component={screen.component}
                            options={{
                                title: screen.title || screen.name,
                                tabBarIcon: ({ color, size, focused }) => (
                                    <Ionicons
                                        name={screen.icon(color, size, focused)}
                                        color={color}
                                        size={size}
                                    />
                                ),
                                tabBarLabel: screen.title || screen.name,
                            }}
                        />
                    ))}
                </Tab.Navigator>
            </View>
    );
}

export default ClientTabNavigator;