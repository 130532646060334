import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import { useNavigation } from "@react-navigation/native";
import { FontAwesome } from '@expo/vector-icons';
import {formatRole} from "../../../utils/format";

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
    container: {
        width: width - 20,
        marginVertical: 5,
        borderRadius: 15,
        backgroundColor: '#FFFFFF',
        padding: 10,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        elevation: 5,
    },
    nameContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    displayName: {
        fontSize: 18,
        fontWeight: '600',
        color: '#333',
        marginLeft: 5
    },
    statusContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
    statusRow: {
        flexDirection: 'row',
        alignItems: 'center',
        margin: 3,
        paddingVertical: 3,
        paddingHorizontal: 5,
        borderRadius: 10,
        backgroundColor: '#F6F6F6',
        borderWidth: 1,
        borderColor: '#E2E2E2',
    },
    statusTitle: {
        fontSize: 12,
        color: '#555',
        fontWeight: '600',
        marginRight: 3,
    },
    statusValue: {
        fontSize: 12,
        color: '#222',
        fontWeight: 'bold',
    },
    viewProfileButton: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 5,
        paddingVertical: 3,
    },
    viewProfileButtonText: {
        marginLeft: 5,
        color: '#007AFF',
        fontSize: 14,
        fontWeight: '600',
    },
    arrowIcon: {
        marginLeft: 6,
    },
    contactContainer: {
        marginLeft: 5,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 5,
    },
    contactIcon: {
        marginRight: 10,
    },
    contactEmail: {
        fontSize: 14,
        color: '#007AFF',
        fontWeight: '600',
    },
});

const KundenCard = ({ client, vermittlerId }) => {
    const navigation = useNavigation();

    const getProgressColor = (percentage) => {
        if (percentage < 40) return '#FF4C4C';
        if (percentage < 80) return '#FFA500';
        return '#4CAF50';
    };

    return (
        <View style={styles.container}>
            <View style={styles.nameContainer}>
                <Text style={styles.displayName}>
                    {client.firstName} {client.lastName}
                </Text>
                <View style={styles.statusRow}>
                    <Text style={styles.statusValue}>{formatRole(client.role)}</Text>
                </View>
            </View>

            <View style={styles.contactContainer}>
                <FontAwesome name="envelope" size={16} color="#007AFF" style={styles.contactIcon} />
                <Text style={styles.contactEmail}>{client.email}</Text>
            </View>

            <View style={styles.statusContainer}>
                <View style={styles.statusRow}>
                    <Text style={styles.statusTitle}>Progress:</Text>
                    <Text style={[styles.statusValue, { color: getProgressColor(client.documentProgress) }]}>
                        {client.documentProgress} %
                    </Text>
                </View>
                <View style={styles.statusRow}>
                    <Text style={styles.statusTitle}>Status:</Text>
                    <Text style={styles.statusValue}>{client.active ? 'Active' : 'Inactive'}</Text>
                </View>
                <View style={styles.statusRow}>
                    <Text style={styles.statusTitle}>Kreditanfrage:</Text>
                    <Text style={styles.statusValue}>{client.kreditanfrage ? 'Ja' : 'Nein'}</Text>
                </View>
                <View style={styles.statusRow}>
                    <Text style={styles.statusTitle}>Banken weiterleiten:</Text>
                    <Text style={styles.statusValue}>{client.forwardedBanks ? 'Ja' : 'Nein'}</Text>
                </View>
            </View>

            <TouchableOpacity
                style={styles.viewProfileButton}
                onPress={() => navigation.navigate('ViewClientDetails', { clientId: client.id, vermittlerId: vermittlerId })}>
                <Text style={styles.viewProfileButtonText}>Zu Benutzerdetails gehen</Text>
                <FontAwesome name="angle-right" size={18} color="#007AFF" style={styles.arrowIcon} />
            </TouchableOpacity>
        </View>
    );
};

export default KundenCard;
