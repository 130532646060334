import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

const styles = StyleSheet.create({
    notificationWidgetContainer: {
        marginVertical: 4,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 20,
        borderWidth: 0.5,
        borderColor: '#d3d3d3',
        borderRadius: 12,
        backgroundColor: '#ffffff',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        elevation: 5,
    },
    leftContent: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
    },
    notificationIcon: {
        marginRight: 20,
    },
    notificationDetails: {
        fontSize: 16,
        color: '#4a4a4a',
        flexShrink: 1,
    },
    actionButton: {
        marginLeft: 5,
        backgroundColor: '#2196F3',
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 20,
    },
    actionButtonText: {
        fontSize: 16,
        color: '#ffffff',
        fontWeight: 'bold',
    }
});

const NotificationWidget = ({ uploaderName, uploadTime }) => (
    <View style={styles.notificationWidgetContainer}>
        <View style={styles.leftContent}>
            <FontAwesome5 style={styles.notificationIcon} name="file-upload" size={30} color="#2196F3" />
            <Text style={styles.notificationDetails}>{uploaderName} hat alle Unterlagen um {uploadTime} hochgeladen.</Text>
        </View>
        <TouchableOpacity style={styles.actionButton}>
            <Text style={styles.actionButtonText}>Ansehen</Text>
        </TouchableOpacity>
    </View>
);

export default NotificationWidget;
