import React, { useState, createContext } from 'react';
import {getUser} from "../api/endpoint";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const updateUser = async () => {
        const userData = await getUser();
        setUser(userData);
    };

    return (
        <UserContext.Provider value={{ user, setUser, updateUser }}>
            {children}
        </UserContext.Provider>
    );
};
