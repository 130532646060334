import React, {useContext, useState} from 'react';
import {
    ScrollView,
    StyleSheet,
    TouchableOpacity,
    View,
    Alert,
    Image,
} from 'react-native';
import { TextInput, Text, Button, DefaultTheme } from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import * as ImagePicker from 'expo-image-picker';
import {useNavigation} from '@react-navigation/native';
import {sendCreditRequest} from "../../../api/endpoint";
import {useSnackbar} from "../../../context/SnackbarContext";
import {UserContext} from "../../../context/UserContext";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f5faff',
        padding: 20,
    },
    radioButtonContainer: {
        backgroundColor: '#ffffff',
        padding: 15,
        borderRadius: 15,
        marginBottom: 25,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
    },
    text: {
        color: 'black',
        fontSize: 20,
        marginBottom: 10,
    },
    customRadioContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 5,
    },
    customRadio: {
        width: 20,
        height: 20,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: 'black',
        marginRight: 10,
    },
    customRadioSelected: {
        width: 20,
        height: 20,
        borderRadius: 10,
        backgroundColor: '#27ae60',
        marginRight: 10,
        borderWidth: 2,
        borderColor: 'black',
    },
    customRadioText: {
        fontSize: 18,
    },
    inputContainer: {
        backgroundColor: 'white',
        padding: 15,
        borderRadius: 15,
        marginBottom: 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
    },
    input: {
        backgroundColor: '#ffffff',
    },
    imagePlaceholder: {
        backgroundColor: '#dfe4ea',
        borderRadius: 15,
        width: '100%',
        height: 220,
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 12,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        position: 'relative',
    },
    deleteButton: {
        position: 'absolute',
        top: 5,
        right: 5,
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderRadius: 15,
        padding: 5,
    },
    overlay: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        padding: 10,
        alignItems: 'center',
    },
    changeButton: {
        flexDirection: 'row',
        alignItems: 'center',
        position: 'absolute',
        bottom: 10,
        right: 10,
    },
    shadow: {
        textShadowColor: 'rgba(0, 0, 0, 0.75)',
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 10,
    },
    button: {
        borderRadius: 15,
        marginTop: 25,
        marginBottom: 85,
        backgroundColor: '#3498db',
    },
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f6fa',
    },
});

const RadioButtonCustom = ({ label, value, selectedValue, onValueChange }) => (
    <TouchableOpacity
        style={styles.customRadioContainer}
        onPress={() => onValueChange(value)}
    >
        <View style={selectedValue === value ? styles.customRadioSelected : styles.customRadio} />
        <Text style={styles.customRadioText}>{label}</Text>
    </TouchableOpacity>
);

const Kreditantragsformular = () => {
    const { user } = useContext(UserContext);
    const navigation = useNavigation();

    const [formData, setFormData] = useState({
        userId: user.id,
        kreditTyp: '',
        betrag: '',
        nettoEinkommen: '',
        verfugbaresEinkommen: '',
        laufzeit: '',
    });
    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [errorText, setErrorText] = useState('');
    const { showSnackbar } = useSnackbar();

    const handleFormDataChange = (key, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const pickOrChangeImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        if (!result.canceled) {
            const { uri } = result.assets[0];
            const fileExtension = uri.split('.').pop();

            if (['png', 'jpg', 'jpeg'].includes(fileExtension.toLowerCase())) {
                setSelectedImage(uri);
            } else {
                Alert.alert('Unsupported Format', 'Please select an image in PNG, JPG, or JPEG format.');
            }
        }
    };

    const removeImage = () => {
        setSelectedImage(null);
    };

    const prepareFormData = () => {
        let data = new FormData();
        data.append('userId', formData.userId);
        data.append('kreditTyp', formData.kreditTyp);
        data.append('betrag', formData.betrag);
        data.append('nettoEinkommen', formData.nettoEinkommen);
        data.append('verfugbaresEinkommen', formData.verfugbaresEinkommen);
        data.append('laufzeit', formData.laufzeit);

        if (selectedImage) {
            data.append('bild', {
                uri: selectedImage,
                type: 'image/jpeg',
                name: 'image.jpg',
            });
        }

        return data;
    };

    const inputTheme = {
        ...DefaultTheme,
        roundness: 2,
        colors: {
            ...DefaultTheme.colors,
        },
        fonts: {
            ...DefaultTheme.fonts,
            medium: {
                ...DefaultTheme.fonts.medium,
                fontSize: 12,
            },
        },
    };

    const submitFormData = async (data) => {
        setLoading(true);

        try {
            await sendCreditRequest(data);
            setLoading(false);
            showSnackbar('Ihre Anfrage wurde erfolgreich gesendet!', 'success');
            navigation.navigate('KreditAnzeigen');
        } catch (error) {
            setLoading(false);
            showSnackbar('Ihre Anfrage konnte nicht gesendet werden. Bitte versuchen Sie es später erneut.', 'error');
        }
    };

    const handleSubmit = async () => {
        if (!user.id) {
            Alert.alert('Fehler', 'Benutzer-ID nicht gefunden.');
            return;
        }

        if (!formData.kreditTyp || !formData.betrag || !formData.nettoEinkommen || !formData.verfugbaresEinkommen || !formData.laufzeit) {
            Alert.alert('WARNUNG', 'Bitte füllen Sie alle Felder aus.');
            return;
        }

        if (!selectedImage) {
            Alert.alert('WARNUNG', 'Bitte laden Sie ein Bild hoch.');
            return;
        }

        let data = prepareFormData();

        Alert.alert(
            'Anfrage bestätigen',
            'Sind Sie sicher, dass Sie diese Anfrage senden möchten?',
            [
                {
                    text: 'Abbrechen',
                    onPress: () => console.log('Anfrage abgebrochen'),
                    style: 'cancel'
                },
                {
                    text: 'Bestätigen',
                    onPress: () => submitFormData(data)
                }
            ],
            { cancelable: false }
        );
    };

    const handleLaufzeitChange = (text) => {
        if (text === '') {
            handleFormDataChange('laufzeit', text);
            setErrorText('');
            return;
        }

        if (!isNaN(text) && parseInt(text) >= 1) {
            handleFormDataChange('laufzeit', text);
            setErrorText('');
        } else {
            setErrorText('Bitte geben Sie eine Zahl größer oder gleich 1 ein.');
        }
    };

    return (
        <ScrollView style={styles.container} contentContainerStyle={{ paddingBottom: 30 }}>
            <View style={styles.radioButtonContainer}>
                <Text style={styles.text}>Wählen Sie Ihren Kredit-Typ:</Text>
                <View style={styles.radioGroup}>
                    <RadioButtonCustom
                        label="Immobilienkredit"
                        value="Immobilienkredit"
                        selectedValue={formData.kreditTyp}
                        onValueChange={(value) => handleFormDataChange('kreditTyp', value)}
                    />
                    <RadioButtonCustom
                        label="Autokredit"
                        value="Autokredit"
                        selectedValue={formData.kreditTyp}
                        onValueChange={(value) => handleFormDataChange('kreditTyp', value)}
                    />
                    <RadioButtonCustom
                        label="Sonstiger Anschaffungskredit"
                        value="Sonstiger Anschaffungskredit"
                        selectedValue={formData.kreditTyp}
                        onValueChange={(value) => handleFormDataChange('kreditTyp', value)}
                    />
                </View>
            </View>

            <View style={styles.inputContainer}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <TextInput
                        style={[styles.input, { flex: 1 }]}
                        mode="outlined"
                        label="Kreditbetrag in EUR"
                        keyboardType="numeric"
                        value={formData.betrag}
                        onChangeText={(text) => handleFormDataChange('betrag', text)}
                        theme={inputTheme}
                        dense
                    />
                    <Text style={{ marginLeft: 6 }}>€</Text>
                </View>
            </View>

            <View style={styles.inputContainer}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <TextInput
                        style={[styles.input, { flex: 1 }]}
                        mode="outlined"
                        label="Tragen Sie Ihr derzeitiges Nettoeinkommen ein"
                        keyboardType="numeric"
                        value={formData.nettoEinkommen}
                        onChangeText={(text) => handleFormDataChange('nettoEinkommen', text)}
                        theme={inputTheme}
                        dense
                    />
                    <Text style={{ marginLeft: 6 }}>€</Text>
                </View>
            </View>

            <View style={styles.inputContainer}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <TextInput
                        style={[styles.input, { flex: 1 }]}
                        mode="outlined"
                        label="Tragen Sie Ihr frei verfügbares Einkommen ein"
                        keyboardType="numeric"
                        value={formData.verfugbaresEinkommen}
                        onChangeText={(text) => handleFormDataChange('verfugbaresEinkommen', text)}
                        theme={inputTheme}
                        dense
                    />
                    <Text style={{ marginLeft: 6 }}>€</Text>
                </View>
            </View>

            <View style={styles.inputContainer}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <TextInput
                        style={[styles.input, { flex: 1 }]}
                        mode="outlined"
                        label="Laufzeit des Kredits(mindestens 1 Jahr)"
                        keyboardType="numeric"
                        value={formData.laufzeit}
                        onChangeText={handleLaufzeitChange}
                        theme={inputTheme}
                        dense
                    />
                    <Text style={{ marginLeft: 6 }}>Jahr</Text>
                </View>
                {errorText !== '' && (
                    <Text style={{ color: 'red', marginTop: 5 }}>{errorText}</Text>
                )}
            </View>

            <TouchableOpacity style={styles.imagePlaceholder} onPress={pickOrChangeImage}>
                {selectedImage ? (
                    <Image source={{ uri: selectedImage }} style={{ width: '100%', height: '100%' }} />
                ) : (
                    <>
                        <Icon name="camera" size={40} color="#7f8c8d" />
                        <Text style={{ color: 'black', fontSize: 18, fontWeight: 'bold', marginTop: 10 , textAlign: 'center' }}>
                            Bitte laden Sie ein Bild vom Finanzierungsobjekt hoch
                        </Text>
                    </>
                )}
                {selectedImage && (
                    <>
                        <TouchableOpacity onPress={removeImage} style={styles.deleteButton}>
                            <Icon name="delete-outline" size={30} color="white" style={styles.shadow} />
                        </TouchableOpacity>
                        <View style={styles.overlay}>
                            <TouchableOpacity onPress={pickOrChangeImage} style={styles.changeButton}>
                                <Icon name="image-edit-outline" size={30} color="white" />
                            </TouchableOpacity>
                        </View>
                    </>
                )}
            </TouchableOpacity>

            <Button
                icon="send"
                style={styles.button}
                mode="contained"
                onPress={handleSubmit}
                disabled={loading}
            >
                {loading ? 'Wird gesendet...' : 'Anfrage senden'}
            </Button>
        </ScrollView>
    );
};

export default Kreditantragsformular;
