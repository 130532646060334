import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

const styles = StyleSheet.create({
    widgetContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 20,
        borderRadius: 20,
        marginBottom: 25,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.12,
        shadowRadius: 8,
        elevation: 5,
        backgroundColor: '#FFFFFF'
    },
    widgetLeft: {
        flex: 1,
    },
    widgetRight: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    widgetTitle: {
        fontSize: 20,
        fontWeight: '600',
        color: '#333',
        marginBottom: 5,
    },
    widgetCounter: {
        fontSize: 28,
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 10,
    },
    link: {
        fontSize: 16,
        color: '#007AFF',
        textDecorationLine: 'underline',
    },
    percentagePositive: {
        backgroundColor: '#4CAF50',
        borderRadius: 12,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 12,
        paddingVertical: 6,
        marginBottom: 15,
    },
    percentageNegative: {
        backgroundColor: '#F44336',
        borderRadius: 12,
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 10,
        paddingHorizontal: 12,
        paddingVertical: 6,
        marginBottom: 15,
    },
    percentageText: {
        color: '#fff',
        marginLeft: 8,
        fontWeight: '600',
    },
    iconWrapper: {
        width: 60,
        height: 60,
        borderRadius: 30,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#E0E0E0',
        elevation: 6,
    }
});

const InfoWidget = ({
                        icon,
                        title,
                        count,
                        percentageIncrease,
                        backgroundColor = '#FFFFFF',
                        iconBackgroundColor,
                        style = {},
                        onSeeAllPress
                    }) => {
    const positive = percentageIncrease >= 0;

    return (
        <View style={[styles.widgetContainer, style, { backgroundColor }]}>
            <View style={styles.widgetLeft}>
                <Text style={styles.widgetTitle}>{title}</Text>
                <Text style={styles.widgetCounter}>{count}</Text>
                <TouchableOpacity onPress={onSeeAllPress}>
                    <Text style={styles.link}>Alle {title} anzeigen</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.widgetRight}>
                <View style={positive ? styles.percentagePositive : styles.percentageNegative}>
                    <FontAwesome5 name={positive ? "arrow-up" : "arrow-down"} size={16} color="#fff" />
                    <Text style={styles.percentageText}>{Math.abs(percentageIncrease)}%</Text>
                </View>
                <View style={[styles.iconWrapper, iconBackgroundColor && { backgroundColor: iconBackgroundColor }]}>
                    {icon}
                </View>
            </View>
        </View>
    );
};

export default InfoWidget;
