import React, {useState, useContext} from 'react';
import { ScrollView, View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { API_URL } from '../../../api/config';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useFocusEffect } from '@react-navigation/native';
import {fetchCreditRequestsForUser} from "../../../api/endpoint";
import {UserContext}  from "../../../context/UserContext";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
    },
    request: {
        backgroundColor: '#f8f9fa',
        paddingVertical: 20,
        paddingHorizontal: 15,
        marginBottom: 20,
        borderRadius: 15,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        flexDirection: 'row',
        alignItems: 'center',
    },
    requestTextContainer: {
        marginLeft: 15,
        flexShrink: 1,
    },
    requestTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#343a40',
    },
    requestDetail: {
        fontSize: 16,
    },
    image: {
        width: 120,
        height: 120,
        borderRadius: 15,
        overflow: 'hidden',
    },
    viewIconContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 10,
        padding: 8,
        borderRadius: 20,
    },
    requestButton: {
        marginTop: 20,
        padding: 10,
        borderRadius: 10,
        backgroundColor: '#3498DB',
        alignItems: 'center',
        justifyContent: 'center',
        width: '70%',
    },
    requestButtonText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
    emptyDataContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundColor: '#F5F6F8',
        padding: 20,
        borderRadius: 10,
    },
    emptyDataText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#2C3E50',
        textAlign: 'center',
        marginTop: 10,
    },
});

const KreditAnzeigen = ({ route }) => {
    const { user } = useContext(UserContext);
    const client = route.params?.client;
    const [creditRequests, setcreditRequests] = useState([]);
    const navigation = useNavigation();

    const userId = user.role === 'KREDIT_VERMITTLER' ? client.id : user.id;
    const clientOrAdmin = user.role === 'KREDIT_VERMITTLER' ? client : user;

    useFocusEffect(
        React.useCallback(() => {
            const fetchData = async () => {
                try {
                    const responseData = await fetchCreditRequestsForUser(userId);
                    setcreditRequests(responseData);
                } catch (error) {
                    console.error("Error fetching data", error);
                }
            };

            fetchData();
        }, [])
    );

    return (
        <ScrollView style={styles.container} contentContainerStyle={{ paddingBottom: 30 }}>
            {creditRequests.length > 0 ? (
                creditRequests.map(request => (
                    <View key={request.id} style={styles.request}>
                        <Image
                            source={{ uri: `${API_URL}/api/credit-requests/view/${request.id}` }}
                            style={styles.image}
                            resizeMode="cover"
                        />
                        <View style={{ flex: 1 }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <View style={styles.requestTextContainer}>
                                    <Text style={styles.requestTitle}>{request.kreditTyp}</Text>
                                    <Text style={styles.requestDetail}>Betrag: {request.betrag} EUR</Text>
                                    <Text style={styles.requestDetail}>Laufzeit: {request.laufzeit} Jahre</Text>
                                </View>
                                <TouchableOpacity
                                    style={styles.viewIconContainer}
                                    onPress={() => {
                                        navigation.navigate('KreditanfrageView', {
                                            creditRequestId: request.id,
                                            userId: userId
                                        });
                                    }}
                                >
                                    <Ionicons name="eye" size={24} color="black" />
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                ))
            ) : (
                <View style={styles.emptyDataContainer}>
                    <Ionicons name="information-circle-outline" size={50} color="#2C3E50" />
                    <Text style={styles.emptyDataText}>{clientOrAdmin.firstName} hat noch keine Kreditanfrage</Text>
                    {user.role !== 'KREDIT_VERMITTLER' && (
                        <TouchableOpacity
                            style={styles.requestButton}
                            onPress={() => navigation.navigate('Kreditantragsformular')}
                        >
                            <Text style={styles.requestButtonText}>Kreditantrag stellen</Text>
                        </TouchableOpacity>
                    )}
                </View>
            )}
        </ScrollView>
    );
};

export default KreditAnzeigen;
