import React from 'react';
import { View, Text, TextInput, Switch, StyleSheet, TouchableOpacity, Linking } from 'react-native';

const styles = StyleSheet.create({
    title: {
        fontSize: 22,
        fontWeight: '600',
        marginBottom: 16,
        color: '#444',
    },
    input: {
        width: '100%',
        height: 48,
        borderColor: '#CDCDCD',
        borderWidth: 1,
        borderRadius: 7,
        marginBottom: 12,
        paddingHorizontal: 14,
        fontSize: 16,
        backgroundColor: '#FFF',
    },
    switchContainerWrapper: {
        width: '100%',
        alignItems: 'flex-start',
    },
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 12,
    },
    link: {
        color: '#4A90E2',
        textDecorationLine: 'underline',
    },
    switchText: {
        fontSize: 14,
        color: '#555',
        marginLeft: 5,
    },
    linkText: {
        color: '#4A90E2',
        textDecorationLine: 'underline',
        fontSize: 14,
        fontWeight: '500',
        marginLeft: 5,
    },
});

const PersonalInfoSection = ({ inputFields, privacyPolicyAccepted, setPrivacyPolicyAccepted, termsAndConditionsAccepted, setTermsAndConditionsAccepted }) => {
    return (
        <View>
            <Text style={{ ...styles.title, fontSize: 20 }}>Persönliche Informationen</Text>
            {inputFields.map((input, index) => (
                <TextInput
                    key={index}
                    style={styles.input}
                    placeholder={`${input.required ? `${input.placeholder} (Pflichtfelder)` : input.placeholder}`}
                    value={input.value}
                    onChangeText={input.onChangeText}
                    secureTextEntry={input.secureTextEntry}
                    autoCapitalize="none"
                />
            ))}
            <View style={styles.switchContainerWrapper}>
                <View style={styles.switchContainer}>
                    <Switch value={privacyPolicyAccepted} onValueChange={setPrivacyPolicyAccepted} />
                    <TouchableOpacity onPress={() => Linking.openURL('https://orgaplan-beratung.de/datenschutz')}>
                        <Text style={styles.linkText}>Datenschutzrichtlinie akzeptiert</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.switchContainer}>
                    <Switch
                        value={termsAndConditionsAccepted}
                        onValueChange={setTermsAndConditionsAccepted}
                    />
                    <Text style={styles.switchText}>Allgemeine Geschäftsbedingungen akzeptiert</Text>
                </View>
            </View>
        </View>
    );
};

export default PersonalInfoSection;
