import React, {useState, createContext, useContext} from 'react';

export const SnackbarContext = createContext();

export const useSnackbar = () => {
    return useContext(SnackbarContext);
};

export const SnackbarProvider = ({ children }) => {
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState("");
    const [icon, setIcon] = useState("");
    const [title, setTitle] = useState("");
    const [snackbarStyle, setSnackbarStyle] = useState({});

    const snackbarConfigurations = {
        success: {
            title: "Success",
            icon: "checkmark-outline",
            style: { backgroundColor: '#4CAF50', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        },
        error: {
            title: "Error",
            icon: "close-outline",
            style: { backgroundColor: '#F44336', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        },
        info: {
            title: "Info",
            icon: "information-circle-outline",
            style: { backgroundColor: '#2196F3', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        },
        warning: {
            title: "Warning",
            icon: "alert-outline",
            style: { backgroundColor: '#FFC107', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        }
    };

    const showSnackbar = (message, type, duration = 1500) => {
        const config = snackbarConfigurations[type];

        if (config) {
            setMessage(message || config.message);
            setTitle(config.title);
            setIcon(config.icon);
            setSnackbarStyle(config.style);
            setVisible(true);

            setTimeout(() => {
                setVisible(false);
            }, duration);
        }
    };

    return (
        <SnackbarContext.Provider value={{ visible, showSnackbar, setVisible, message, icon, title, snackbarStyle }}>
            {children}
        </SnackbarContext.Provider>
    );
};
