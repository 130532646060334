import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {API_URL} from './config';
import {Alert} from 'react-native';
import {getFileExtension, getFileType} from "../utils/format";
import * as AuthSession from "expo-auth-session";

//-------------------------------------------- HTTP-fetch Methoden --------------------------------------------

export const fetchAPI = async (method, path, body = null, isFormData = false) => {
    try {
        const url = `${API_URL}${path}`;
        let config = {
            method: method,
            url: url,
            ...(body && {data: body}),
        };

        if (body && (method === 'POST' || method === 'PUT' || method === 'DELETE')) {
            config.headers = {
                'Content-Type': isFormData ? 'multipart/form-data' : 'application/json'
            };
        }

        const response = await axios(config);

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            throw new Error(`Request failed with status ${response.status}`);
        }
    } catch (error) {
        console.error(`Error fetching ${method} request to ${path}:`, error);
        throw error;
    }
};

//-------------------------------------------- OAuth 2.0 ---------------------------------------------

const AUTH_URL = `https://auth.kv.orgaplan-beratung.de/realms/kv/protocol/openid-connect/auth?response_type=code&client_id=kv-app&redirect_uri=kreditunterlagen://return&scope=offline_access`;

export const loginWithOAuth = async () => {
    try {
        const response = await AuthSession.startAsync({authUrl: AUTH_URL, returnUrl: 'kreditunterlagen://return'});
        console.log('OAuth Response:', response);

        if (response.type === 'success') {
            const fixedUserId = '87361e6c-8dc1-4ea2-8005-5bdf5a471f13';

            const accessTokenRequest = new AuthSession.AccessTokenRequest({
                code: response.params.code,
                clientId: "kv-app",
                redirectUri: "kreditunterlagen://return",
                scopes: ["openid", "profile", "email", "offline_access"],
            });

            const responseAccessToken = await AuthSession.exchangeCodeAsync(
                accessTokenRequest,
                {tokenEndpoint: 'https://auth.kv.orgaplan-beratung.de/realms/kv/protocol/openid-connect/token'}
            );

            await AsyncStorage.setItem('userId', fixedUserId);
            await AsyncStorage.setItem('accessToken', responseAccessToken.accessToken);
            await AsyncStorage.setItem('refreshToken', responseAccessToken.refreshToken);
            await AsyncStorage.setItem('issuedAt', JSON.stringify(responseAccessToken.issuedAt));
            console.log(JSON.stringify(responseAccessToken, null, 4));
        }
        return response;
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};

export const access_token = async () => {
    const issued_at = parseInt(await AsyncStorage.getItem('issuedAt'), 10) * 1000;

    if (Date.now() - issued_at < 4.5 * 60 * 1000) {
        return await AsyncStorage.getItem('accessToken');
    }

    try {
        // Refresh the token if it's no longer valid
        const response = await AuthSession.refreshAsync(
            {
                clientId: "kv-app",
                refreshToken: await AsyncStorage.getItem('refreshToken'),
            },
            { tokenEndpoint: 'https://auth.kv.orgaplan-beratung.de/realms/kv/protocol/openid-connect/token' }
        );

        // Save the new tokens and issued_at time
        await AsyncStorage.setItem('accessToken', response.accessToken);
        await AsyncStorage.setItem('refreshToken', response.refreshToken);
        await AsyncStorage.setItem('issuedAt', (Date.now() / 1000).toString());

        // Return the new access token
        return response.accessToken;
    } catch (error) {
        console.error('Error refreshing token:', error);
    }
};

//-------------------------------------------- User-APIs --------------------------------------------

export const logoutUser = (navigation) => {
    Alert.alert(
        "Abmeldung",
        "Möchten Sie sich wirklich abmelden?",
        [
            {text: "Abbrechen", style: "cancel"},
            {
                text: "Ja",
                onPress: async () => {
                    try {
                        // Clear user data from AsyncStorage
                        await AsyncStorage.removeItem('userId');
                        await AsyncStorage.removeItem('accessToken');
                        await AsyncStorage.removeItem('refreshToken');
                        await AsyncStorage.removeItem('issuedAt');

                        // Navigate back to the login screen
                        navigation.navigate('Anmeldung');
                    } catch (error) {
                        console.error('Failed to log out:', error);
                        Alert.alert('Fehler', 'Abmeldung fehlgeschlagen, bitte versuchen Sie es erneut.');
                    }
                }
            }
        ],
        {cancelable: true}
    );
};

export const registerNewClient = async (userData) => {
    try {
        return await fetchAPI('POST', '/api/users/createNewClient', userData);
    } catch (error) {
        console.error('Error during registration:', error);
        throw error;
    }
};

export const loginUser = async (email, password) => {
    try {
        const response = await fetchAPI('POST', '/api/users/login', {email, password});

        if (response.userId) {
            await AsyncStorage.setItem('userId', response.userId.toString());
        }

        return response;
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};

export const getUser = async () => {
    try {
        const userId = await AsyncStorage.getItem('userId');
        if (!userId) {
            throw new Error('User ID not found in AsyncStorage');
        }
        return await fetchAPI('GET', `/api/users/${userId}`);
    } catch (error) {
        console.error('Failed to fetch user data:', error);
        throw error;
    }
};

export const deleteUser = async (userId) => {
    try {
        if (!userId) {
            throw new Error('User ID is missing.');
        }
        return await fetchAPI('DELETE', `/api/users/${userId}`);
    } catch (error) {
        console.error('Error deleting user:', error);
        throw error;
    }
};

export const editUser = async (userData) => {
    try {
        return await fetchAPI('PUT', '/api/users/editUser', userData);
    } catch (error) {
        console.error('Failed to edit user data:', error);
        throw error;
    }
};

export const fetchRoles = async () => {
    try {
        return await fetchAPI('GET', '/api/users/roles');
    } catch (error) {
        console.error('Error fetching roles:', error);
        throw error;
    }
};

export const getClients = async (vermittlerId) => {
    try {
        const path = `/api/vermittler-kunden/clients/${vermittlerId}`;
        return await fetchAPI('GET', path);
    } catch (error) {
        console.error('Failed to fetch clients: ', error);
        throw error;
    }
};

export const getClient = async (vermittlerId, clientId) => {
    try {
        const path = `/api/vermittler-kunden/${vermittlerId}/${clientId}`;
        return await fetchAPI('GET', path);
    } catch (error) {
        console.error('Failed to fetch client data:', error);
        throw error;
    }
};

//-------------------------------------------- Document-APIs --------------------------------------------

export const uploadDocument = async (selectedFile, category, userId, newName = null) => {
    const formData = new FormData();
    const fileType = getFileType(selectedFile.name);

    if (!fileType) {
        console.error('Unsupported file type:', selectedFile.name);
        throw new Error('Unsupported file type');
    }

    const finalName = newName ? (newName + "." + getFileExtension(selectedFile.name)) : selectedFile.name;

    formData.append('file', {
        uri: selectedFile.uri,
        type: fileType,
        name: finalName
    });
    formData.append('type', category);
    formData.append('userId', userId);

    try {
        return await fetchAPI('POST', '/api/documents/upload', formData, true);
    } catch (error) {
        console.error('Failed to upload the document:', error);
        throw error;
    }
};

export const getDocuments = async (userId) => {
    try {
        return await fetchAPI('GET', `/api/documents/${userId}`);
    } catch (error) {
        console.error('Error retrieving documents:', error);
        throw error;
    }
};

export const deleteDocument = async (documentId, userId) => {
    try {
        const path = `/api/documents/delete`;
        const body = {documentId, userId};
        return await fetchAPI('DELETE', path, body);
    } catch (error) {
        console.error('Fehler beim Löschen des Dokuments:', error);
        throw error;
    }
};

export const viewDocument = async (documentId) => {
    const fileURL = `${API_URL}/api/documents/view/${documentId}`;
    return fileURL;
};

//-------------------------------------------- Request-APIs --------------------------------------------

export const sendCreditRequest = async (data) => {
    try {
        const path = `/api/credit-requests/send`;
        return await fetchAPI('POST', path, data, true);
    } catch (error) {
        console.error('There was an error uploading the data!', error);
        throw error;
    }
};

export const getCreditRequests = async (userId) => {
    try {
        if (!userId) {
            throw new Error('User ID not found in AsyncStorage');
        }
        const path = `/api/credit-requests/${userId}`;
        return await fetchAPI('GET', path);
    } catch (error) {
        console.error('Failed to fetch credit requests: ', error);
        throw error;
    }
};

export const getCreditRequest = async (userId, creditRequestId) => {
    try {
        const path = `/api/credit-requests/${userId}/${creditRequestId}`;
        return await fetchAPI('GET', path);
    } catch (error) {
        console.error('Failed to fetch credit request:', error);
        throw error;
    }
};

export const deleteCreditRequest = async (userId, creditRequestId) => {
    try {
        const path = `/api/credit-requests/${userId}/${creditRequestId}`;
        return await fetchAPI('DELETE', path);
    } catch (error) {
        console.error('Fehler beim Löschen der Kreditanfrage:', error);
        throw error;
    }
};

export const fetchCreditRequestsForUser = async (userId) => {
    try {
        const path = `/api/credit-requests/${userId}`;
        return await fetchAPI('GET', path);
    } catch (error) {
        console.error('Error fetching credit requests for user:', error);
        throw error;
    }
};

//-------------------------------------------- Companies-APIs --------------------------------------------

export const getCompanybyuserId = async (userId) => {
    try {
        if (!userId) {
            throw new Error('User ID is missing.');
        }
        return await fetchAPI('GET', `/api/companies/${userId}`);
    } catch (error) {
        console.error('Failed to fetch company details by user ID: ', error);
        throw error;
    }
};

export const updateCompany = async (companyData) => {
    try {
        return await fetchAPI('PUT', '/api/companies/editCompany', companyData);
    } catch (error) {
        console.error('Failed to update company: ', error);
        throw error;
    }
};

//-------------------------------------------- Banking Forward-API --------------------------------------------

export const activateForwardBanks = async (kundenId, forwardBanksStatus) => {
    try {
        const body = {
            forwardedBanks: forwardBanksStatus
        };
        return await fetchAPI('PUT', `/api/vermittler-kunden/activateForwardBanks/${kundenId}`, body);
    } catch (error) {
        console.error('Failed to activate forward banks: ', error);
        throw error;
    }
};

export const savePercentageUploaded = async (kundenId, percentageUploaded) => {
    try {
        const data = {
            kundenId: kundenId,
            percentageUploaded: percentageUploaded
        };
        return await fetchAPI('PUT', `/api/vermittler-kunden/savePercentageUploaded`, data);
    } catch (error) {
        console.error('Error while saving percentage uploaded:', error);
        throw error;
    }
};