import React, { useState } from 'react';
import {View, Text, StyleSheet, ScrollView, Alert, TouchableOpacity, TextInput} from 'react-native';
import * as Clipboard from "expo-clipboard";
import {Ionicons, MaterialIcons} from '@expo/vector-icons';
import {formatDate, formatRole} from "../../../utils/format";
import {editUser} from "../../../api/endpoint";
import {useSnackbar} from "../../../context/SnackbarContext";

const styles = StyleSheet.create({
    container: {
        padding: 20,
        backgroundColor: '#F7F9FC',
        flex: 1,
    },
    detailRow: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginVertical: 5,
        borderBottomWidth: 0.5,
        borderColor: '#E5E5E5',
        paddingBottom: 10,
    },
    detailLabel: {
        fontSize: 14,
        fontWeight: '500',
        color: '#757575',
        marginBottom: 5,
    },
    detailValueContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
    },
    detailValue: {
        fontSize: 20,
        fontWeight: '600',
        color: '#333333',
    },
    icon: {
        marginBottom: 3
    },
    editableDetailValue: {
        flex: 1,
        fontSize: 20,
        fontWeight: '600',
        color: '#333333',
        borderWidth: 1,
        borderColor: '#007BFF',
        borderRadius: 5,
        padding: 5,
        marginRight: 10,
    },
    iconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    }
});

const ClientProfile = ({ route }) => {
    const client = route.params?.client;
    const [editedClient, setEditedClient] = useState({ ...client });
    const [editingField, setEditingField] = useState(null);
    const { showSnackbar } = useSnackbar();

    if (!client) {
        return <Text>Loading...</Text>;
    }

    const copyToClipboard = (content) => {
        Clipboard.setString(content);
        Alert.alert('Benachrichtigung', 'In die Zwischenablage kopiert!');
    };

    const handleEditPress = (key) => {
        setEditingField(key);
    };

    const handleSave = async (key) => {
        try {
            await editUser(editedClient);
            showSnackbar('Die Informationen wurden aktualisiert!', 'success');
            setEditingField(null);
            route.params.client = { ...editedClient };
        } catch (error) {
            console.error('Error updating user data:', error);
            showSnackbar('Beim Aktualisieren der Informationen ist ein Fehler aufgetreten!', 'error');
        }
    };

    const handleCancel = () => {
        setEditedClient({ ...client });
        setEditingField(null);
    };

    const handleChange = (key, value) => {
        setEditedClient(prev => ({ ...prev, [key]: value }));
    };

    const fieldTitles = [
        { key: "firstName", title: "First Name", editable: true },
        { key: "lastName", title: "Last Name", editable: true },
        { key: "phoneNumber", title: "Phone", copyable: true, editable: true },
        { key: "email", title: "Email", copyable: true, editable: true },
        { key: "role", title: "Role", formatter: formatRole },
        { key: "active", title: "Status", formatter: value => value ? 'Active' : 'Inactive' },
        { key: "createdAt", title: "Created", formatter: formatDate },
    ];

    return (
        <ScrollView style={styles.container} contentContainerStyle={{ paddingBottom: 40 }}>
            {fieldTitles.map(field => (
                <View key={field.key} style={styles.detailRow}>
                    <Text style={styles.detailLabel}>{field.title}:</Text>
                    {editingField === field.key ? (
                        <View style={styles.detailValueContainer}>
                            <TextInput
                                style={styles.editableDetailValue}
                                value={editedClient[field.key]}
                                onChangeText={(value) => handleChange(field.key, value)}
                                autoCapitalize="none"
                            />
                            <View style={styles.iconContainer}>
                                <TouchableOpacity onPress={() => handleSave(field.key)}>
                                    <Ionicons name="checkmark-circle-outline" size={24} color="green" />
                                </TouchableOpacity>
                                <TouchableOpacity onPress={handleCancel}>
                                    <Ionicons name="close-circle-outline" size={24} color="red" />
                                </TouchableOpacity>
                            </View>
                        </View>
                    ) : (
                        <View style={styles.detailValueContainer}>
                            <Text style={styles.detailValue}>
                                {field.formatter ? field.formatter(editedClient[field.key]) : editedClient[field.key]}
                            </Text>
                            <View style={styles.iconContainer}>
                                {field.copyable && (
                                    <MaterialIcons
                                        name="content-copy"
                                        size={24}
                                        color="gray"
                                        onPress={() => copyToClipboard(editedClient[field.key])}
                                        style={styles.icon}
                                    />
                                )}
                                {field.editable && (
                                    <TouchableOpacity onPress={() => handleEditPress(field.key)}>
                                        <Ionicons name="md-create" size={24} color="#2c3e50" style={styles.icon}/>
                                    </TouchableOpacity>
                                )}
                            </View>
                        </View>
                    )}
                </View>
            ))}
        </ScrollView>
    );
};

export default ClientProfile;