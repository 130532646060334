import React from 'react';
import { SafeAreaView, StyleSheet, StatusBar, Platform } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import Routes from './src/navigator/Routes';
import { Dimensions } from 'react-native';
import SnackBarMessenge from "./src/components/SnackBarMessenge";
import { SafeAreaProvider } from "react-native-safe-area-context";
import AppBar from "./src/components/AppBar";
import {UserProvider} from "./src/context/UserContext";
import {SnackbarProvider} from "./src/context/SnackbarContext";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        width: windowWidth,
        height: windowHeight
    },
});

const App = () => {
    return (
        <SnackbarProvider>
            <UserProvider>
                <SafeAreaView style={styles.container}>
                    <SafeAreaProvider>
                        <NavigationContainer>
                            <StatusBar
                                hidden={false}
                                backgroundColor={Platform.OS === 'android' ? "#000" : undefined}
                                barStyle={Platform.OS === 'android' ? "light-content" : "dark-content"}
                            />
                            <AppBar />
                            <Routes />
                            <SnackBarMessenge />
                        </NavigationContainer>
                    </SafeAreaProvider>
                </SafeAreaView>
            </UserProvider>
        </SnackbarProvider>
    );
};

export default App;
