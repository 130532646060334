import React from 'react';
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';
import StartScreen from '../components/StartScreen';
import RegistrierungKunden from '../components/kunden/registry/Registrierung';
import ClientTabNavigator from "./ClientTabNavigator";
import AdminTabNavigator from './AdminTabNavigator';
import Document from '../components/kunden/document/Document';
import DocumentViewScreenKunden from '../components/kunden/document/DocumentViewScreen';
import SettingKunden from "../components/Setting";
import KreditanfrageView from '../components/kunden/request/KreditanfrageView';
import Kreditanfrage from "../components/kunden/request/Kreditanfrage";
import Account from "../components/Account";
import ClientDetails from "../components/kreditvermittler/user/ClientDetails";
import KreditAnzeigenKunden from "../components/kunden/request/KreditAnzeigen";
import Firmendaten from "../components/kunden/company/Firmendaten";
import ClientProfile from "../components/kreditvermittler/user/ClientProfile";

const Stack = createStackNavigator();

const Routes = () => {

    return (
        <Stack.Navigator
            screenOptions={{
                headerStyle: {
                    backgroundColor: '#3498db',
                    elevation: 0,
                    shadowOpacity: 0,
                    borderBottomWidth: 0.5,
                    borderBottomColor: 'gray',
                },
                headerTintColor: 'white',
                headerTitleStyle: {
                    fontWeight: '600',
                    fontSize: 18,
                    marginLeft: 10
                },
                headerTitleAlign: 'center',
                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                headerBackTitle: 'Zurück',
                gestureEnabled: true,
            }}
        >
            <Stack.Screen
                name="Anmeldung"
                component={StartScreen}
                options={{
                    headerShown: false,
                    gestureEnabled: false
                }}
            />
            <Stack.Screen
                name="RegistrierungKunden"
                component={RegistrierungKunden}
                options={{
                    title: 'Registrierung',
                }}
            />
            <Stack.Screen
                name="ClientTabNavigator"
                component={ClientTabNavigator}
                options={{
                    headerShown: false,
                    gestureEnabled: false
                }}
            />
            <Stack.Screen
                name="AdminTabNavigator"
                component={AdminTabNavigator}
                options={{
                    headerShown: false,
                    gestureEnabled: false
                }}
            />
            <Stack.Screen
                name="Document"
                component={Document}
                options={{ title: 'Dokumente' }}
            />
            <Stack.Screen
                name="Account"
                component={Account}
                options={{ title: 'Konto'}}
            />
            <Stack.Screen
                name="DocumentViewScreenKunden"
                component={DocumentViewScreenKunden}
                options={({ route }) => ({ headerTitle: route.params.fileName })}
            />
            <Stack.Screen
                name="EinstellungenKunden"
                component={SettingKunden}
                options={{ title: 'Einstellungen'}}
            />
            <Stack.Screen
                name="Kreditanfrage"
                component={Kreditanfrage}
                options={{ title: 'Kreditanfrage'}}
            />
            <Stack.Screen
                name="KreditAnzeigenKunden"
                component={KreditAnzeigenKunden}
                options={{ title: 'Kreditanzeigen'}}
            />
            <Stack.Screen
                name="KreditanfrageView"
                component={KreditanfrageView}
                options={{ title: 'Kredit Details'}}
            />
            <Stack.Screen
                name="ViewClientDetails"
                component={ClientDetails}
                options={{ title: 'Details' }}
            />
            <Stack.Screen
                name="ViewProfile"
                component={ClientProfile}
                options={{ title: 'Profil' }}
            />
            <Stack.Screen
                name="Firmendaten"
                component={Firmendaten}
                options={{ title: 'Firmendaten' }}
            />
        </Stack.Navigator>
    );
};

export default Routes;
