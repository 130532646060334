import React, {useContext, useEffect, useState} from 'react';
import {View, Text, StyleSheet, Image, Dimensions, TouchableOpacity, Alert, ScrollView} from 'react-native';
import { API_URL } from '../../../api/config';
import { useNavigation } from '@react-navigation/native';
import {deleteCreditRequest, getCreditRequest} from "../../../api/endpoint";
import {useSnackbar} from "../../../context/SnackbarContext";
import useUserPolling from "../../../hooks/useUserPolling";

const { width, height } = Dimensions.get('window');

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 30,
        backgroundColor: '#F7F9FC',
    },
    imageContainer: {
        alignItems: 'center',
        marginBottom: 40,
        borderRadius: 20,
        backgroundColor: '#FFFFFF',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 6 },
        shadowOpacity: 0.35,
        shadowRadius: 8,
        elevation: 12,
    },
    image: {
        width: width - 60,
        height: height * 0.35,
        borderRadius: 20,
    },
    requestTitle: {
        fontSize: 28,
        fontWeight: 'bold',
        color: '#1A202C',
        textAlign: 'center',
        marginBottom: 30,
    },
    divider: {
        height: 1,
        backgroundColor: '#CBD5E0',
    },
    requestDetail: {
        fontSize: 20,
        color: '#2D3748',
        marginVertical: 25,
        fontFamily: 'System',
    },
    button: {
        backgroundColor: '#E53E3E',
        borderRadius: 50,
        paddingVertical: 15,
        paddingHorizontal: 40,
        alignItems: 'center',
        marginTop: 40,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.3,
        shadowRadius: 4,
        elevation: 6,
    },
    buttonText: {
        color: '#FFFFFF',
        fontWeight: '700',
        fontSize: 20,
    }
});

const KreditanfrageView = ({ route }) => {
    const user = useUserPolling();
    const [creditData, setCreditData] = useState({});
    const creditRequestId = route.params.creditRequestId;
    const userId = route.params?.userId || user.id;
    const navigation = useNavigation();
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCreditRequest(userId, creditRequestId);
                setCreditData(data);
            } catch (error) {
                console.error("Error fetching credit data", error);
            }
        };

        fetchData();
    }, [creditRequestId]);

    const onDeleteRequest = async () => {
        try {
            await deleteCreditRequest(user.id, creditRequestId);
            showSnackbar('Anfrage erfolgreich gelöscht!', 'success');
            navigation.navigate('KreditAnzeigen');
        } catch (error) {
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Beim Löschen des Kreditanfrage ist ein Fehler aufgetreten.';
            console.error('Error:', errorMessage);
            showSnackbar(errorMessage, 'error');
        }
    };

    const showDeleteConfirmation = () => {
        Alert.alert(
            "Anfrage löschen",
            "Möchten Sie diese Anfrage wirklich löschen?",
            [
                {
                    text: "Abbrechen",
                    onPress: () => console.log("Abbrechen gedrückt"),
                    style: "cancel"
                },
                { text: "OK", onPress: onDeleteRequest }
            ],
            { cancelable: false }
        );
    }

    return (
        <ScrollView style={styles.container} contentContainerStyle={{ paddingBottom: 120 }}>
            <Text style={styles.requestTitle}>{creditData.kreditTyp}</Text>
            <View style={styles.imageContainer}>
                <Image
                    source={{ uri: `${API_URL}/api/credit-requests/view/${creditRequestId}` }}
                    style={styles.image}
                    resizeMode="cover"
                />
            </View>
            <View style={styles.divider} />
            <Text style={styles.requestDetail}>Betrag: {creditData.betrag || 'keine Angaben'} EUR</Text>
            <View style={styles.divider} />
            <Text style={styles.requestDetail}>Laufzeit: {creditData.laufzeit || 'keine Angaben'} Jahre</Text>
            <View style={styles.divider} />
            <Text style={styles.requestDetail}>Netto Einkommen: {creditData.nettoEinkommen || 'keine Angaben'} EUR</Text>
            <View style={styles.divider} />
            <Text style={styles.requestDetail}>Verfugbares Einkommen: {creditData.verfugbaresEinkommen || 'keine Angaben'} EUR</Text>
            <View style={styles.divider} />
            {user.role !== 'KREDIT_VERMITTLER' && !user.forwardedBanks && (
                <TouchableOpacity style={styles.button} onPress={showDeleteConfirmation}>
                    <Text style={styles.buttonText}>Anfrage löschen</Text>
                </TouchableOpacity>
            )}
        </ScrollView>
    );
}

export default KreditanfrageView;
