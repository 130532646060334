import React, {useState, useEffect, useContext} from 'react';
import { ScrollView, View, Text, TextInput, TouchableOpacity, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {editUser, logoutUser} from "../api/endpoint";
import {UserContext} from "../context/UserContext";
import {Ionicons} from "@expo/vector-icons";
import {useSnackbar} from "../context/SnackbarContext";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
        backgroundColor: '#fff',
    },
    profileContainer: {
        marginTop: 10,
        alignItems: 'center',
        marginBottom: 15,
    },
    userName: {
        fontWeight: 'bold',
        fontSize: 28,
        color: '#2c3e50',
    },
    fieldContainer: {
        marginBottom: 10,
        borderBottomColor: '#e0e0e0',
        borderBottomWidth: 0.5,
        paddingBottom: 10,
    },
    fieldName: {
        fontSize: 16,
        marginBottom: 8,
        color: '#7f8c8d',
    },
    fieldValue: {
        fontSize: 18,
        color: '#34495e',
    },
    input: {
        height: 50,
        borderColor: '#bdc3c7',
        borderWidth: 1,
        borderRadius: 12,
        paddingLeft: 20,
        backgroundColor: '#fff',
        color: '#34495e',
        fontSize: 18,
    },
    button: {
        backgroundColor: '#3498db',
        paddingVertical: 12,
        marginHorizontal: 60,
        borderRadius: 25,
        alignItems: 'center',
        marginTop: 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 6,
        elevation: 5,
    },
    buttonText: {
        color: '#ecf0f1',
        fontSize: 18,
        fontWeight: '600',
    },
});

const Account = () => {
    const fieldTitles = [
        { key: 'firstName', title: 'Vorname' },
        { key: 'lastName', title: 'Nachname' },
        { key: 'phoneNumber', title: 'Telefonnummer' },
        { key: 'email', title: 'E-Mail' },
    ];

    const navigation = useNavigation();
    const { user, updateUser } = useContext(UserContext);
    const [editingField, setEditingField] = useState(null);
    const [editedUser, setEditedUser] = useState(user);
    const { showSnackbar } = useSnackbar();

    const filterUserData = (data) => {
        return {
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            email: data.email,
            privacyPolicyAccepted: data.privacyPolicyAccepted,
            termsAndConditionsAccepted: data.termsAndConditionsAccepted
        };
    };

    const handleFieldSave = async (field) => {
        try {
            const filteredData = filterUserData({ ...editedUser, [field]: editedUser[field] });
            await editUser(filteredData);
            showSnackbar('Update successful', 'success');
            setEditingField(null);
            updateUser();
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const handleInputChange = (field, value) => {
        setEditedUser((prevUser) => ({
            ...prevUser,
            [field]: value,
        }));
    };

    const handleLogout = () => {
        logoutUser(navigation);
    };

    if (!user) {
        return <Text style={styles.fieldName}>Laden...</Text>;
    }

    const handleCancelEdit = () => {
        setEditingField(null);
        setEditedUser(user);
    };

    return (
        <ScrollView style={styles.container} contentContainerStyle={{ paddingBottom: 80 }}>
            <View style={styles.profileContainer}>
                <Text style={styles.userName}>{editedUser.firstName} {editedUser.lastName}</Text>
            </View>

            {fieldTitles.map((field) => (
                <View style={styles.fieldContainer} key={field.key}>
                    <Text style={styles.fieldName}>{field.title}:</Text>
                    {editingField !== field.key ? (
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', }}>
                            <Text style={styles.fieldValue}>{editedUser[field.key]}</Text>
                            <TouchableOpacity onPress={() => setEditingField(field.key)} style={{ marginBottom: 10 }}>
                                <Ionicons name="md-create" size={24} color="#2c3e50" />
                            </TouchableOpacity>
                        </View>
                    ) : (
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <TextInput
                                style={[styles.input, { flex: 1 }]}
                                value={editedUser[field.key]}
                                onChangeText={(value) => handleInputChange(field.key, value)}
                                autoCapitalize="none"
                            />
                            <TouchableOpacity onPress={() => handleFieldSave(field.key)} style={{ padding: 10 }}>
                                <Ionicons name="md-checkmark" size={24} color="#2c3e50" />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={handleCancelEdit} style={{ padding: 10 }}>
                                <Ionicons name="md-close" size={24} color="#2c3e50" />
                            </TouchableOpacity>
                        </View>
                    )}
                </View>
            ))}

            <TouchableOpacity style={styles.button} onPress={handleLogout}>
                <Text style={styles.buttonText}>Abmelden</Text>
            </TouchableOpacity>
        </ScrollView>
    );
};

export default Account;
