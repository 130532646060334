import React, {useContext, useEffect, useState} from 'react';
import {View, Text, Modal, TouchableOpacity, StyleSheet, TextInput} from 'react-native';
import {formatText, getFileType} from "../../../utils/format";
import {getDocuments, savePercentageUploaded, uploadDocument} from "../../../api/endpoint";
import {UserContext}  from "../../../context/UserContext";
import {useSnackbar} from "../../../context/SnackbarContext";
import * as Progress from 'react-native-progress';

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalView: {
        width: 280,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "white",
        borderRadius: 15,
        padding: 25,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    button: {
        backgroundColor: '#007AFF',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 10,
        marginBottom: 15,
        alignItems: 'center',
    },
    buttonText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 16,
    },
    text: {
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 25,
        fontWeight: '500',
    },
    fileName: {
        fontSize: 16,
        fontWeight: '400',
        marginBottom: 15,
        color: '#333',
        backgroundColor: '#f7f7f7',
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 7,
        overflow: 'hidden',
        maxWidth: '100%',
        textAlign: 'center',
    },
    fileNameInput: {
        width: '100%',
        height: 50,
        borderColor: '#ced4da',
        borderWidth: 1,
        borderRadius: 8,
        marginBottom: 20,
        paddingHorizontal: 16,
        backgroundColor: '#ffffff',
        fontSize: 14,
        color: '#495057',
        fontFamily: 'System',
        elevation: 2,
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
    },
    renameText: {
        fontSize: 16,
        color: '#007AFF',
        textDecorationLine: 'underline',
        marginBottom: 15,
    },
});

const DocumentPickerPopup = ({ visible, onClose, onPick, category, selectedFile, refreshDocuments }) => {
    const { user } = useContext(UserContext);
    const [isRenaming, setIsRenaming] = useState(false);
    const [newFileName, setNewFileName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
        if (visible) {
            setIsRenaming(false);
            setNewFileName('');
        }
    }, [visible]);

    const handleUpload = async () => {
        setIsLoading(true);
        if (!selectedFile || !selectedFile.name) {
            showSnackbar('Bitte wählen Sie eine Datei aus.', 'warning');
            setIsLoading(false);
            return;
        }

        try {
            await uploadDocument(selectedFile, category, user.id, newFileName);
            const documentResponse = await getDocuments(user.id);
            await savePercentageUploaded(user.id, documentResponse.percentageUploaded);

            showSnackbar('Dokument hochgeladen!', 'success');
            onPick();
            await refreshDocuments();
        } catch (error) {
            console.error('Fehler beim Hochladen der Datei:', error);
            showSnackbar('Beim Hochladen des Dokuments ist ein Fehler aufgetreten.', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={visible}
            onRequestClose={onClose}
        >
            <View style={styles.modalContainer}>
                <View style={styles.modalView}>
                    <Text style={styles.text}>
                        {category ? formatText(category) : ''}
                    </Text>
                    {isLoading ? (
                        <Progress.Circle size={30} indeterminate />
                    ) : (
                        <>
                            {isRenaming ? (
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <TextInput
                                        style={styles.fileNameInput}
                                        value={newFileName}
                                        onChangeText={setNewFileName}
                                        placeholder="Neuen Dateinamen eingeben"
                                        autoCapitalize="none"
                                    />
                                    <Text style={{ marginBottom: 12 }}>
                                        .{selectedFile && getFileType(selectedFile.name).split('/').pop()}
                                    </Text>
                                </View>
                            ) : (
                                selectedFile && <Text style={styles.fileName}>{selectedFile.name}</Text>
                            )}
                            <Text
                                style={styles.renameText}
                                onPress={() => setIsRenaming(!isRenaming)}
                            >
                                {isRenaming ? 'Originalnamen beibehalten' : 'Umbenennen'}
                            </Text>
                            <TouchableOpacity onPress={handleUpload} style={styles.button}>
                                <Text style={styles.buttonText}>Hochladen</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={onClose} style={styles.button}>
                                <Text style={styles.buttonText}>Schließen</Text>
                            </TouchableOpacity>
                        </>
                    )}
                </View>
            </View>
        </Modal>
    );
};

export default DocumentPickerPopup;