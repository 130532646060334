import React, { useCallback, useState } from 'react';
import { View, Text, StyleSheet, ScrollView, Alert, Switch, TouchableOpacity } from 'react-native';
import { Ionicons } from "@expo/vector-icons";
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { activateForwardBanks, deleteUser, getClient } from "../../../api/endpoint";
import { useSnackbar } from "../../../context/SnackbarContext";
import * as Progress from 'react-native-progress';
import {formatDate} from "../../../utils/format";

const styles = StyleSheet.create({
    container: {
        padding: 20,
        backgroundColor: '#F7F8FA',
        flex: 1,
    },
    header: {
        fontSize: 28,
        fontWeight: '700',
        textAlign: "center",
        color: '#2C2D30',
        marginBottom: 20,
    },
    buttonContainer: {
        marginTop: 15,
    },
    menuItem: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderBottomWidth: 0.5,
        borderBottomColor: '#E0E0E0',
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        marginBottom: 10,
    },
    menuItemText: {
        flex: 1,
        marginLeft: 20,
        fontSize: 18,
        color: '#333',
    },
    deleteButton: {
        marginTop: 40,
        paddingVertical: 12,
        paddingHorizontal: 20,
        backgroundColor: '#FF4D4D',
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    deleteButtonText: {
        marginLeft: 8,
        fontSize: 18,
        color: '#FFF',
        fontWeight: '600',
    },
    widgetContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#F7F8FA',
    },
    detailLabel: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 8
    },
    lastUpdateText: {
        fontSize: 14,
        color: '#333',
        marginTop: 8,
        fontWeight: '500',
        fontFamily: 'System',
        letterSpacing: 0.1,
        lineHeight: 20,
    },
    forwardBankWidget: {
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: 15,
        width: '48%',
        backgroundColor: '#FFFFFF',
        borderRadius: 12,
        elevation: 4,
    },
    documentWidget: {
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: 15,
        width: '48%',
        backgroundColor: '#FFFFFF',
        borderRadius: 12,
        elevation: 4,
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 10,
    },
    refreshButton: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        borderRadius: 25,
        backgroundColor: 'white',
        position: 'absolute',
        top: 0,
        right: 10,
    },
});

const MenuItem = ({ iconName, iconColor, text, onPress }) => (
    <TouchableOpacity style={styles.menuItem} onPress={onPress}>
        <Ionicons name={iconName} size={24} color={iconColor} />
        <Text style={styles.menuItemText}>{text}</Text>
        <Ionicons name="chevron-forward" size={24} color="#888888" />
    </TouchableOpacity>
);

const ClientDetails = ({ route }) => {
    const clientId = route.params?.clientId;
    const vermittlerId = route.params?.vermittlerId;
    const [client, setClient] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigation = useNavigation();
    const { showSnackbar } = useSnackbar();

    const fetchData = async () => {
        try {
            const data = await getClient(vermittlerId, clientId);
            if (JSON.stringify(client) !== JSON.stringify(data)) {
                setClient(data);
            }
        } catch (error) {
            console.error("Error fetching client data:", error);
            showSnackbar('Fehler beim Abrufen der Kundendaten', 'error');
        }
    };

    useFocusEffect(
        useCallback(() => {
            let cancelled = false;

            const fetchData = async () => {
                if (cancelled || hasError) return;

                try {
                    const data = await getClient(vermittlerId, clientId);
                    if (!cancelled && JSON.stringify(client) !== JSON.stringify(data)) {
                        setClient(data);
                    }
                } catch (error) {
                    console.error("Error fetching client data:", error);
                    setHasError(true);
                }
            };

            fetchData();

            const interval = setInterval(() => {
                fetchData();
            }, 5000);

            return () => {
                cancelled = true;
                clearInterval(interval);
            };
        }, [client, hasError])
    );

    if (!client) {
        return <Text>Loading...</Text>;
    }

    const lastUpdateText = client.forwardedBanksAt
        ? `Weitergeleitet am: ${formatDate(client.forwardedBanksAt)}`
        : '';

    const handleRefresh = async () => {
        await fetchData();
    };

    const toggleForwardBanks = async () => {
        setIsLoading(true);
        try {
            const updatedStatus = !client.forwardedBanks;
            await activateForwardBanks(client.id, updatedStatus);
            showSnackbar('Banken Weiterleitung Status erfolgreich aktualisiert', 'success');
            setClient({ ...client, forwardedBanks: updatedStatus });
        } catch (error) {
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Fehler beim Aktualisieren des Banken Weiterleitung Status.';
            console.error('Error:', errorMessage);
            Alert.alert('Fehler', errorMessage);

            await fetchData();
        } finally {
            setIsLoading(false);
        }
    };

    const handleViewProfile = () => {
        navigation.navigate('ViewProfile', { client: client });
    };

    const handleViewCreditRequest = () => {
        navigation.navigate('KreditAnzeigenKunden', { client: client });
    };

    const handleViewDocument = () => {
        navigation.navigate('Document', { client: client });
    };

    const handleDeleteUser = () => {
        Alert.alert(
            'Löschbestätigung',
            'Möchten Sie diesen Benutzer wirklich löschen?',
            [
                {
                    text: 'Abbrechen',
                    style: 'cancel'
                },
                {
                    text: 'Löschen',
                    onPress: async () => {
                        try {
                            await deleteUser(client.id);
                            showSnackbar('Benutzer wurde erfolgreich gelöscht.', 'success');
                            navigation.goBack();
                        } catch (error) {
                            Alert.alert('Fehler', 'Benutzer konnte nicht gelöscht werden.');
                        }
                    },
                    style: 'destructive'
                },
            ],
            { cancelable: true }
        );
    };

    return (
        <ScrollView style={styles.container}>
            <Text style={styles.header}>{client.firstName} {client.lastName}</Text>

            <TouchableOpacity style={styles.refreshButton} onPress={handleRefresh}>
                <Ionicons name="refresh" size={30} color="#888" />
            </TouchableOpacity>

            <View style={styles.widgetContainer}>
                <View style={styles.documentWidget}>
                    <Text style={styles.title}>Fortschritt</Text>
                    <Progress.Circle
                        progress={client.documentProgress / 100}
                        size={100}
                        thickness={15}
                        color={
                            client.documentProgress < 50
                                ? `rgb(255, ${Math.round(2.55 * client.documentProgress)}, 0)`
                                : client.documentProgress < 100
                                    ? `rgb(${Math.round(255 - 2.55 * (client.documentProgress - 50))}, 255, 0)`
                                    : '#00FF00'
                        }
                        unfilledColor="#EDEDED"
                        borderWidth={0}
                        showsText={true}
                        formatText={p => `${client.documentProgress} %`}
                        textStyle={{ color: '#333', fontSize: 16 }}
                    />
                </View>

                <View style={styles.forwardBankWidget}>
                    <Text style={styles.detailLabel}>Banken Weiterleitung</Text>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => {
                            if (client.documentProgress < 100 || !client.kreditanfrage) {
                                Alert.alert(
                                    "Benachrichtigung",
                                    `Der Status kann nicht geändert werden, da der Dokumentenupload noch nicht bei 100% liegt oder die Kreditanfrage fehlt. Der aktuelle Fortschritt beträgt ${client.documentProgress}% und der aktuelle Kreditanfrage ist ${client.kreditanfrage ? 'vorhanden' : 'nicht vorhanden'}.`,
                                    [{ text: "OK", onPress: () => fetchData() }]
                                );
                            }
                        }}
                    >
                        {isLoading ? (
                            <Progress.Circle size={30} indeterminate />
                        ) : (
                            <Switch
                                trackColor={{ false: "#E0E0E0", true: "#4D90FE" }}
                                thumbColor={client.forwardedBanks ? "#FFFFFF" : "#D0D0D0"}
                                onValueChange={toggleForwardBanks}
                                value={client.forwardedBanks}
                                disabled={client.documentProgress < 100 || !client.kreditanfrage}
                            />
                        )}
                    </TouchableOpacity>
                    <Text style={styles.lastUpdateText}>{lastUpdateText}</Text>
                </View>
            </View>
            <View style={styles.buttonContainer}>
                <MenuItem iconName="person" iconColor="#4D90FE" text="Profil anzeigen" onPress={handleViewProfile} />
                <MenuItem iconName="card" iconColor="#00A859" text="Kreditanfragen anzeigen" onPress={handleViewCreditRequest} />
                <MenuItem iconName="document-text" iconColor="#FFA400" text="Dokument anzeigen" onPress={handleViewDocument} />
            </View>

            <TouchableOpacity style={styles.deleteButton} onPress={handleDeleteUser}>
                <Ionicons name="trash" size={24} color="#FFF" />
                <Text style={styles.deleteButtonText}>Diesen Kunden löschen</Text>
            </TouchableOpacity>
        </ScrollView>
    );
};

export default ClientDetails;
