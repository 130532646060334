import React, {useContext, useState} from 'react';
import {
    View,
    Text,
    Image,
    TextInput,
    StyleSheet,
    ScrollView,
    TouchableOpacity,
    KeyboardAvoidingView,
    Platform,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Button from './Button';
import {getUser, loginUser} from "../api/endpoint";
import * as Linking from 'expo-linking';
import {UserContext} from "../context/UserContext";
import {useSnackbar} from "../context/SnackbarContext";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f9f9f9',
        padding: 32,
    },
    content: {
        flex: 1,
        marginTop: 40,
    },
    title: {
        fontSize: 38,
        fontWeight: 'bold',
        marginBottom: 32,
        color: '#333',
        alignSelf: 'center',
    },
    input: {
        width: '100%',
        height: 50,
        borderColor: '#007AFF',
        borderWidth: 1,
        borderRadius: 15,
        marginBottom: 20,
        paddingHorizontal: 20,
        fontSize: 18,
        backgroundColor: '#FFFFFF',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    footer: {
        alignItems: 'center',
        marginBottom: 20,
        borderColor: '#e1e1e1',
    },
    linkText: {
        color: '#007AFF',
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 20,
    },
    copyrightText: {
        color: '#999',
        fontSize: 12,
        textAlign: 'center',
        opacity: 0.8,
    }
});

const StartScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { setUser } = useContext(UserContext);
    const { showSnackbar } = useSnackbar();

    const navigation = useNavigation();

    const handleLogin = async () => {
        try {
            const loginData = await loginUser(email, password);

            if (loginData && loginData.userId) {
                const userData = await getUser();
                setUser(userData)

                if (userData && userData.role) {
                    const homeKundenRoles = ['PRIVAT_KUNDEN', 'FIRMEN_KUNDEN'];
                    if (homeKundenRoles.includes(userData.role)) {
                        navigateToClientTabNavigator();
                    } else if (userData.role === 'KREDIT_VERMITTLER') {
                        navigateToAdminTabNavigator();
                    } else {
                        showSnackbar('Unbekannter Benutzertyp', 'error');
                    }
                } else {
                    showSnackbar('Fehler beim Abrufen von Benutzerdaten', 'error');
                }

                showSnackbar(loginData.message, 'success');
            }
        } catch (error) {
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Anmeldung fehlgeschlagen.';
            console.error('Login error:', errorMessage);
            showSnackbar(errorMessage, 'error');
        }
    };

    const navigateToClientTabNavigator = () => {
        navigation.navigate('ClientTabNavigator');
    };

    const navigateToAdminTabNavigator = () => {
        navigation.navigate('AdminTabNavigator');
    };

    return (
        <ScrollView contentContainerStyle={styles.container}>
            <KeyboardAvoidingView
                behavior={Platform.OS === "ios" ? "padding" : "height"}
                style={{ flex: 1 }}
            >
                <View style={styles.content}>
                    <Text style={styles.title}>Willkommen</Text>
                    <TextInput
                        style={styles.input}
                        placeholder="Email"
                        value={email}
                        onChangeText={setEmail}
                        autoCapitalize="none"
                    />
                    <TextInput
                        style={styles.input}
                        placeholder="Passwort"
                        secureTextEntry
                        value={password}
                        onChangeText={setPassword}
                        autoCapitalize="none"
                    />
                    <Button title="Anmelden" onPress={handleLogin} />
                </View>
            </KeyboardAvoidingView>

            {/* Footer */}
            <View style={styles.footer}>
                <TouchableOpacity onPress={() => Linking.openURL('https://orgaplan-beratung.de/')}>
                    <Image
                        source={require('../../assets/ORGAPLANLOGO.jpg')}
                        style={{ width: 150, height: 50, alignSelf: 'center', marginBottom: 20 }}
                    />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => Linking.openURL('https://orgaplan-beratung.de/impressum')}>
                    <Text style={styles.linkText}>Impressum</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => Linking.openURL('https://orgaplan-beratung.de/datenschutz')}>
                    <Text style={styles.linkText}>Datenschutz</Text>
                </TouchableOpacity>
                <Text style={styles.copyrightText}> Powered of © ORGAPLAN Beratung 2023</Text>
            </View>
        </ScrollView>
    );
};

export default StartScreen;
