import React, { useRef } from 'react';
import ModalDropdown from 'react-native-modal-dropdown';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { logoutUser } from "../api/endpoint";
import {useNavigation} from "@react-navigation/native";

const styles = StyleSheet.create({
    dropdownContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    dropdownStyle: {
        width: 150,
        height: 50,
        marginTop: 18,
        backgroundColor: '#F8F8F8',
        borderColor: '#B0B0B0',
        borderWidth: 1,
        borderRadius: 10,
    },
    dropdownRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: 50,
        paddingLeft: 15,
    },
    dropdownText: {
        fontSize: 16,
        color: '#333',
    },
    iconStyle: {
        marginRight: 8,
        color: '#FF4500',
    },
    userIconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});

const UserDropdown = () => {
    const dropdownRef = useRef(null);
    const navigation = useNavigation();

    const handleLogout = () => {
        logoutUser(navigation);
    };

    const displayDropdown = () => {
        dropdownRef.current?.show();
    };

    return (
        <View style={styles.dropdownContainer}>
            <TouchableOpacity onPress={displayDropdown} style={styles.userIconContainer}>
                <Ionicons name='person-outline' size={25} color='white' />
            </TouchableOpacity>
            <ModalDropdown
                ref={dropdownRef}
                options={['Abmelden']}
                dropdownStyle={styles.dropdownStyle}
                defaultValue=""
                renderRow={(option) => (
                    <TouchableOpacity style={styles.dropdownRow} onPress={handleLogout}>
                        <Ionicons name="log-out-outline" size={20} color="#333" style={styles.iconStyle} />
                        <Text style={styles.dropdownText}>{option}</Text>
                    </TouchableOpacity>
                )}
            />
        </View>
    );
};

export default UserDropdown;
