import React, {useContext, useEffect} from 'react';
import {View, StyleSheet, ScrollView, Text} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import UserGreetingWidget from "../../UserGreetingWidget";
import MenuItemWidget from "./MenuItemWidget";
import {UserContext} from "../../../context/UserContext";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
    },
    menuList: {
        marginTop: 15,
    },
    menuItem: {
        flexDirection: 'row',
        marginVertical: 10,
        padding: 15,
        borderRadius: 15,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
        elevation: 2,
    },
    icon: {
        marginRight: 20,
    },
    text: {
        fontSize: 18,
        flex: 1,
    },
    chevronIcon: {
        marginLeft: 20,
    }
});

const menuItems = [
    { iconName: 'document', label: 'Meine Dokumente', backgroundColor: '#FAF3E0', color: '#555', iconColor: '#555' },
    { iconName: 'card', label: 'Kreditanfrage', backgroundColor: '#AED6F1', color: '#21618C', iconColor: '#154360' },
];

const Home = () => {
    const navigation = useNavigation();
    const { user, updateUser } = useContext(UserContext);

    useEffect(() => {
        updateUser();
    }, []);

    const handleMenuItemPress = (label) => {
        console.log(`Pressed ${label}`);
        if (label === 'Meine Dokumente') {
            navigation.navigate('Document');
        }
        if (label === 'Kreditanfrage') {
            navigation.navigate('Kreditanfrage');
        }
    };

    return (
        <ScrollView style={styles.container}>
            <UserGreetingWidget name= {user.firstName} />
            <View style={styles.menuList}>
                {menuItems.map((item, index) => (
                    <MenuItemWidget
                        key={index}
                        item={item}
                        onPress={() => handleMenuItemPress(item.label)}
                    />
                ))}
            </View>
        </ScrollView>
    );
};

export default Home;