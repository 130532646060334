import React from 'react';
import {View, Text, TouchableOpacity, StyleSheet, Alert} from 'react-native';
import AntDesign from "react-native-vector-icons/AntDesign";

const styles = StyleSheet.create({
    paginationContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
        borderRadius: 30,
        marginVertical: 5,
    },
    arrowButton: {
        width: 25,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderRadius: 20,
        marginHorizontal: 3,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 2
    },
    arrowText: {
        fontSize: 20,
        color: '#555'
    },
    pageButton: {
        width: 25,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 3,
        backgroundColor: '#FFFFFF',
        borderRadius: 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 2
    },
    pageButtonActive: {
        width: 25,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 3,
        backgroundColor: '#007BFF',
        borderRadius: 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 2
    },
    pageText: {
        fontSize: 18,
        color: '#555'
    },
    pageTextActive: {
        fontSize: 18,
        color: 'white'
    }
});

const MAX_BUTTONS = 3;

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handlePreviousPage = () => {
        if (currentPage === 1) {
            Alert.alert("Achtung", "Sie befinden sich auf der ersten Seite!");
        } else {
            onPageChange(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage === totalPages) {
            Alert.alert("Achtung", "Sie befinden sich auf der letzten Seite!");
        } else {
            onPageChange(currentPage + 1);
        }
    };

    const getVisiblePages = () => {
        if (currentPage <= MAX_BUTTONS) {
            return Array.from({ length: Math.min(totalPages, MAX_BUTTONS) }).map((_, i) => i + 1);
        }
        if (currentPage > totalPages - MAX_BUTTONS + 1) {
            return Array.from({ length: MAX_BUTTONS }).map((_, i) => totalPages - MAX_BUTTONS + 1 + i).filter(page => page <= totalPages);
        }
        return Array.from({ length: MAX_BUTTONS }).map((_, i) => currentPage + i - 1);
    };

    const visiblePages = getVisiblePages();

    return (
        <View style={styles.paginationContainer}>
            <TouchableOpacity
                style={styles.arrowButton}
                onPress={handlePreviousPage}
            >
                <AntDesign name="left" size={20} color="#555" />
            </TouchableOpacity>

            {visiblePages.map((page) => (
                <TouchableOpacity
                    key={page}
                    style={currentPage === page ? styles.pageButtonActive : styles.pageButton}
                    onPress={() => onPageChange(page)}
                >
                    <Text style={currentPage === page ? styles.pageTextActive : styles.pageText}>
                        {page}
                    </Text>
                </TouchableOpacity>
            ))}

            {currentPage <= totalPages - MAX_BUTTONS && (
                <>
                    <Text style={{...styles.pageText, marginHorizontal: 5}}>...</Text>
                    <TouchableOpacity
                        style={styles.pageButton}
                        onPress={() => onPageChange(totalPages)}
                    >
                        <Text style={styles.pageText}>{totalPages}</Text>
                    </TouchableOpacity>
                </>
            )}

            <TouchableOpacity
                style={styles.arrowButton}
                onPress={handleNextPage}
            >
                <AntDesign name="right" size={20} color="#555" />
            </TouchableOpacity>
        </View>
    );
};

export default Pagination;
