import React, {useState, useEffect, useContext} from 'react';
import {View, Text, FlatList, Button, StyleSheet, TouchableOpacity, Alert} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import DocumentPickerPopup from './DocumentPickerPopup';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { formatText } from "../../../utils/format";
import Tooltip from 'react-native-walkthrough-tooltip';
import {deleteDocument, getDocuments, getUser, savePercentageUploaded, viewDocument} from "../../../api/endpoint";
import {useSnackbar} from "../../../context/SnackbarContext";
import DocumentProgress from "./DocumentProgress";
import useUserPolling from "../../../hooks/useUserPolling";
import {UserContext} from "../../../context/UserContext";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f4f4f4',
    },
    progressHeader: {
        borderRadius: 5,
        justifyContent: "center",
        height: 150
    },
    itemContainer: {
        backgroundColor: '#ffffff',
        padding: 15,
        marginVertical: 10,
        marginHorizontal: 15,
        borderRadius: 10,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
        elevation: 5,
    },
    documentType: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginVertical: 5,
    },
    documentList: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        marginVertical: 5,
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 5,
        backgroundColor: '#f5f5f5',

    },
    highlightedItemText: {
        fontSize: 20,
        textAlign: 'left',
        color: '#2c3e50',
        fontWeight: '600',
        padding: 5,
        paddingHorizontal: 8,
        borderRadius: 8,
    },
    iconHelp: {
        padding: 5,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
    },
    button: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        margin: 5,
        backgroundColor: '#008CBA',
        borderRadius: 10,
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        textAlign: "center"
    },
});

const DOCUMENT_INFO = [
    { type: 'GEHALTSABRECHNUNG', description: 'Für Gehaltsabrechnung, bitte Ihr Gehaltszettel der letzten drei Monate hochladen.' },
    { type: 'STEUERBESCHEID', description: 'Für Steuerbescheid, bitte Ihren letzten Steuerbescheid hochladen.' },
    { type: 'AUSWEISKOPIE', description: 'Für Ausweiskopie, bitte eine klare Kopie Ihres gültigen Personalausweises oder Reisepasses hochladen.' },
    { type: 'KONTOGUTHABEN', description: 'Für Kontoguthaben, bitte einen aktuellen Kontoauszug, der Ihr Guthaben zeigt, hochladen.' },
    { type: 'VERMÖGENSAUFSTELLUNG', description: 'Für Vermögensaufstellung, bitte ein Dokument hochladen, das eine Übersicht über Ihr gesamtes Vermögen bietet.' },
    { type: 'JAHRESABSCHLUSS', description: 'Für Jahresabschluss, bitte den letzten von einem Wirtschaftsprüfer geprüften Jahresabschluss hochladen.' },
    { type: 'BETRIEBSWIRTSCHAFTLICHE_AUSWERTUNG', description: 'Für Betriebswirtschaftliche Auswertung, bitte die letzten betriebswirtschaftlichen Auswertungen hochladen.' },
    { type: 'UNTERLAGEN_GESCHÄFTSFÜHRER', description: 'Für Unterlagen Geschäftsführer, bitte relevante Unterlagen oder Zertifikate, die Ihre Position und Qualifikation als Geschäftsführer bestätigen, hochladen.' },
];

const Document = ({ route }) => {
    const user = useUserPolling();
    const { setUser } = useContext(UserContext);
    const client = route.params?.client;
    const userId = user.role === 'KREDIT_VERMITTLER' ? client.id : user.id;
    const clientOrAdmin = user.role === 'KREDIT_VERMITTLER' ? client : user;

    const navigation = useNavigation();
    const [documents, setDocuments] = useState({});
    const [percentageUploaded, setPercentageUploaded] = useState(clientOrAdmin.documentProgress);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [fileToDelete, setFileToDelete] = useState({ category: null, index: null });

    const [currentInfo, setCurrentInfo] = useState("");
    const [currentTooltipType, setCurrentTooltipType] = useState(null);

    const { showSnackbar } = useSnackbar();

    const showTooltip = (documentType) => {
        const docInfo = DOCUMENT_INFO.find(info => info.type === documentType);
        if (docInfo) {
            setCurrentInfo(docInfo.description);
            setCurrentTooltipType(documentType);
        } else {
            setCurrentTooltipType(null);
        }
    }

    const updateUserData = async () => {
        try {
            const updatedUser = await getUser();
            setUser(updatedUser);
        } catch (error) {
            console.error('Error updating user data:', error);
        }
    };

    const fetchDocuments = async () => {
        try {
            const documentResponse = await getDocuments(userId);

            if (documentResponse) {
                setDocuments(documentResponse.documents);
                setPercentageUploaded(documentResponse.percentageUploaded);
            }
        } catch (error) {
            console.error('Fehler beim Abrufen der Dokumente:', error);
            Alert.alert("Verbindungsfehler",
                "Es scheint ein Problem mit Ihrer Internetverbindung zu geben oder der Server antwortet nicht. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut."
            );

        }
    };

    useEffect(() => {
        fetchDocuments();
    }, []);

    const openDocument = async (documentType, documentId, documents) => {
        if (user.id) {
            const fileName = documents[documentType]?.find(doc => doc.id === documentId)?.fileName || `${documentId}.pdf`;

            const fileURL = await viewDocument(documentId);
            navigation.navigate('DocumentViewScreenKunden', {fileURL, fileName});
        } else {
            console.error("UserId is not found in state.");
        }
    };

    const pickDocument = () => {
        if (selectedFile) {
            const newDocuments = { ...documents };
            newDocuments[selectedCategory].push({ fileName: selectedFile.name });
            setDocuments(newDocuments);
            setSelectedFile(null);
        }
        setModalVisible(false);
    };

    const showModal = async (category, type) => {
        setSelectedCategory(category);
        let result;

        try {
            if (type === 'document') {
                result = await DocumentPicker.getDocumentAsync({ type: '*/*', multiple: true });
                if (result.type === 'success') {
                    setSelectedFile(result);
                    setModalVisible(true);
                }
            } else if (type === 'image') {
                result = await ImagePicker.launchImageLibraryAsync({
                    mediaTypes: ImagePicker.MediaTypeOptions.Images,
                    allowsEditing: true,
                    quality: 1,
                });

                if (!result.canceled && result.assets && result.assets.length > 0) {
                    const selectedAsset = result.assets[0];
                    setSelectedFile({
                        name: selectedAsset.uri.split('/').pop(),
                        uri: selectedAsset.uri
                    });
                    setModalVisible(true);
                }
            }
        } catch (error) {
            console.error('Error during document/image picking:', error);
        }
    };

    const removeDocument = (category, index) => {
        const newDocuments = { ...documents };
        newDocuments[category].splice(index, 1);
        setDocuments(newDocuments);
    };

    const requestRemoveDocument = (category, index) => {
        setFileToDelete({ category, index });
        setConfirmModalVisible(true);
    };

    const confirmRemoveDocument = async () => {
        const { category, index } = fileToDelete;
        try {
            const documentId = documents[category][index]?.id;

            const result = await deleteDocument(documentId, user.id);
            const documentResponse = await getDocuments(user.id);

            if (result) {
                removeDocument(category, index);
                await savePercentageUploaded(user.id, documentResponse.percentageUploaded);
                showSnackbar('Dokument erfolgreich gelöscht', 'success');
                await fetchDocuments();
            } else {
                console.error('Fehler beim Löschen des Dokuments:');
                showSnackbar('Beim Löschen des Dokuments ist ein Fehler aufgetreten', 'error');
            }
        } catch (error) {
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Beim Löschen des Dokuments ist ein Fehler aufgetreten.';
            console.error('Error:', errorMessage);
            Alert.alert('Fehler', errorMessage);
            await updateUserData();
        }

        setConfirmModalVisible(false);
        setFileToDelete({ category: null, index: null });
    };

    const renderItem = ({ item: documentType }) => (
        <View style={styles.itemContainer}>
            <View style={styles.documentType}>
                <Text style={styles.highlightedItemText}>{formatText(documentType)}</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Tooltip
                        disableShadow={true}
                        isVisible={currentTooltipType === documentType}
                        content={<Text>{currentInfo}</Text>}
                        onClose={() => setCurrentTooltipType(null)}
                    >
                        <Ionicons
                            name="help-circle-outline"
                            size={24}
                            onPress={() => showTooltip(documentType)}
                            style={styles.iconHelp}
                        />
                    </Tooltip>
                </View>
            </View>
            {
                user.role !== 'KREDIT_VERMITTLER' &&
                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={styles.button} onPress={() => showModal(documentType, 'document')}>
                        <Ionicons name="document" size={24} color="#fff" />
                        <Text style={styles.buttonText}>PDF Dokument hochladen</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button} onPress={() => showModal(documentType, 'image')}>
                        <Ionicons name="image" size={24} color="#fff" />
                        <Text style={styles.buttonText}>Foto hochladen</Text>
                    </TouchableOpacity>
                </View>
            }
            {documents[documentType]?.map((doc, index) => (
                <View style={styles.documentList} key={index}>
                    <Button
                        title={typeof doc.fileName === 'string' ? doc.fileName : 'Unknown Document'}
                        onPress={() => openDocument(documentType, doc.id, documents)}
                    />
                    {
                        user.role !== 'KREDIT_VERMITTLER' && (
                            user.forwardedBanks ?
                                <Ionicons
                                    name="lock-closed"
                                    size={24}
                                    color="grey"
                                    onPress={() => Alert.alert(
                                        "Einschränkung",
                                        "Sie können dieses Dokument nicht löschen, wenn Banken Weiterleitung aktiviert ist.",
                                        [
                                            {
                                                text: "OK",
                                                onPress: () => updateUserData()
                                            }
                                        ]
                                    )}
                                />
                                :
                                <Ionicons
                                    name="md-remove-circle-outline"
                                    size={24}
                                    color="red"
                                    onPress={() => requestRemoveDocument(documentType, index)}
                                />
                        )
                    }
                </View>
            ))}
        </View>
    );

    return (
        <View style={styles.container}>
            <View style={styles.progressHeader}>
                <DocumentProgress
                    percentageUploaded={percentageUploaded}
                    user={clientOrAdmin}
                    fetchDocument={fetchDocuments}
                    updateUser={updateUserData}
                />
            </View>

            <DocumentPickerPopup
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                onPick={pickDocument}
                category={selectedCategory}
                selectedFile={selectedFile}
                refreshDocuments={fetchDocuments}
                percentageUploaded={percentageUploaded}
            />
            <DeleteConfirmationModal
                visible={confirmModalVisible}
                onConfirm={confirmRemoveDocument}
                onCancel={() => setConfirmModalVisible(false)}
            />
            <FlatList
                data={Object.keys(documents)}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
                contentContainerStyle={{ paddingBottom: 30 }}
            />
        </View>
    );
};

export default Document;
