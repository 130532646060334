import { useState, useEffect, useContext } from 'react';
import { UserContext } from "../context/UserContext";
import { getUser } from "../api/endpoint";

const useUserPolling = () => {
    const { user, setUser } = useContext(UserContext);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        let cancelled = false;

        const updateUser = async () => {
            if (cancelled || hasError) return;
            try {
                const updatedUser = await getUser();
                if (!cancelled && JSON.stringify(updatedUser) !== JSON.stringify(user)) {
                    setUser(updatedUser);
                }
            } catch (error) {
                console.error("Error fetching user: ", error);
                setHasError(true);
            }
        };

        const intervalId = setInterval(updateUser, 5000);

        return () => {
            cancelled = true;
            clearInterval(intervalId);
        };
    }, [user, hasError]);

    return user;
};

export default useUserPolling;
