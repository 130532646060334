import React from 'react';
import { Text, TouchableOpacity, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const styles = StyleSheet.create({
    menuItem: {
        flexDirection: 'row',
        marginVertical: 10,
        padding: 15,
        borderRadius: 15,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
        elevation: 2,
    },
    icon: {
        marginRight: 20,
    },
    text: {
        fontSize: 18,
        flex: 1,
    },
    chevronIcon: {
        marginLeft: 20,
    }
});

const MenuItemWidget = ({ item, onPress }) => {
    return (
        <TouchableOpacity
            style={[styles.menuItem, { backgroundColor: item.backgroundColor }]}
            onPress={onPress}
        >
            <Ionicons
                name={item.iconName}
                size={30}
                color={item.iconColor}
                style={styles.icon}
            />
            <Text style={[styles.text, { color: item.color }]}>{item.label}</Text>
            <Ionicons
                name="chevron-forward"
                size={20}
                color={item.iconColor}
                style={styles.chevronIcon}
            />
        </TouchableOpacity>
    );
};

export default MenuItemWidget;
