export const getFileExtension = (fileName) => {
    return fileName.split('.').pop().toLowerCase();
};

export const getFileType = (fileName) => {
    const extension = getFileExtension(fileName);
    if (!extension) return null;

    const mimeTypes = {
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        gif: 'image/gif',
        bmp: 'image/bmp',
        tiff: 'image/tiff',
        tif: 'image/tiff',
        pdf: 'application/pdf'
    };
    return mimeTypes[extension] || null;
};

export const capitalizeEachWord = (string) => {
    return string.replace(/\b\w/g, char => char.toUpperCase()).replace(/([A-Z])\w/g, match => match[0] + match.slice(1).toLowerCase());
};

export const formatText = (value) => {
    const formattedText = value.replace("_", " ").toLowerCase();

    let result = capitalizeEachWord(formattedText);

    result = result.replace(/([öäü])\w/g, match => match[0] + match[1].toLowerCase());

    return result;
};

export const formatRole = (role) => {
    let formattedRole = role.replace(/_/g, '').toLowerCase();
    return formattedRole.charAt(0).toUpperCase() + formattedRole.slice(1);
};

export const formatDate = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Europe/Berlin',
        hourCycle: 'h24'
    };
    return new Date(dateString).toLocaleDateString('de-DE', options);
};
