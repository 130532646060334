import React, {useContext, useEffect, useState} from 'react';
import {View, Text, ScrollView, StyleSheet, TouchableOpacity, TextInput} from 'react-native';
import {getCompanybyuserId, updateCompany} from "../../../api/endpoint";
import {UserContext}  from "../../../context/UserContext";
import {Ionicons} from "@expo/vector-icons";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
        backgroundColor: '#F9F9FC',
    },
    sectionHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        paddingHorizontal: 20,
        paddingVertical: 15,
        borderRadius: 10,
        backgroundColor: '#FFF',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 8,
        elevation: 5,
    },
    headerTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#333',
    },
    editButton: {
        color: '#007BFF',
        fontSize: 18,
    },
    infoContainer: {
        marginTop: 15,
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderRadius: 8,
        backgroundColor: '#FFF',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 3,
    },
    label: {
        fontSize: 18,
        color: '#444',
        fontWeight: '600',
        marginBottom: 5,
    },
    inputRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    input: {
        flex: 1,
        height: 40,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    saveButtonContainer: {
        marginVertical: 20,
    }
});

const Firmendaten = () => {
    const fieldTitles = [
        { key: "companyName", title: "Firmenname", required: true },
        { key: "streetNumber", title: "Straße Nr.", required: true },
        { key: "postalCode", title: "PLZ", required: true },
        { key: "city", title: "Ort", required: true },
        { key: "country", title: "Land", required: true },
        { key: 'industry', title: 'Branche' },
        { key: 'numberOfEmployees', title: 'Mitarbeiteranzahl' },
        { key: 'ceo', title: 'Geschäftsführer' },
        { key: 'court', title: 'Gericht' },
        { key: 'commercialRegisterNumber', title: 'Handelsregisternummer' },
        { key: 'vatId', title: 'Umsatzsteuer-Identifikationsnummer' },
        { key: 'companyEmail', title: 'Email' },
        { key: 'phone', title: 'Telefon' },
        { key: 'fax', title: 'Telefax' },
        { key: 'website', title: 'Website' },
    ];

    const { user } = useContext(UserContext);
    const [company, setCompany] = useState({});
    const [editingField, setEditingField] = useState(null);
    const [editedCompany, setEditedCompany] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchCompanyData = async () => {
            try {
                setIsLoading(true);
                const companyData = await getCompanybyuserId(user.id);
                setCompany(companyData);
            } catch (error) {
                console.error('Error fetching company data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCompanyData();
    }, []);

    if (isLoading) {
        return <Text>Loading...</Text>;
    }

    const handleEditPress = (key) => {
        setEditingField(key);
        setEditedCompany({ ...company });
    };

    const handleSave = async (key) => {
        try {
            await updateCompany(editedCompany);
            setCompany(editedCompany);
            setEditingField(null);
        } catch (error) {
            console.error('Error updating company data:', error);
        }
    };

    const handleCancel = () => {
        setEditingField(null);
        setEditedCompany({ ...company });
    };

    const handleChange = (key, value) => {
        setEditedCompany({ ...editedCompany, [key]: value });
    };

    return (
        <ScrollView style={styles.container} contentContainerStyle={{ paddingBottom: 80 }}>
            <View style={styles.sectionHeader}>
                <Text style={styles.headerTitle}>Firma und Adresse</Text>
            </View>
            {fieldTitles.map((field) => (
                <View key={field.key} style={styles.infoContainer}>
                    <Text style={styles.label}>{field.title} {field.required && '*'}</Text>
                    <View style={styles.inputRow}>
                        {editingField === field.key ? (
                            <>
                                <TextInput
                                    style={styles.input}
                                    value={editingField === field.key ? editedCompany[field.key] : company[field.key]}
                                    onChangeText={(value) => handleChange(field.key, value)}
                                    autoCapitalize="none"
                                />
                                <TouchableOpacity onPress={() => handleSave(field.key)}>
                                    <Ionicons name="checkmark-circle-outline" size={24} color="green" />
                                </TouchableOpacity>
                                <TouchableOpacity onPress={handleCancel}>
                                    <Ionicons name="close-circle-outline" size={24} color="red" />
                                </TouchableOpacity>
                            </>
                        ) : (
                            <>
                                <Text>{company[field.key]}</Text>
                                <TouchableOpacity onPress={() => handleEditPress(field.key)} style={{ marginBottom: 8 }}>
                                    <Ionicons name="md-create" size={24} color="#2c3e50" />
                                </TouchableOpacity>
                            </>
                        )}
                    </View>
                </View>
            ))}
        </ScrollView>
    );
};

export default Firmendaten;