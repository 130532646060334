import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    section: {
        borderRadius: 15,
        padding: 20,
        backgroundColor: '#fff',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.12,
        shadowRadius: 8,
        elevation: 5,
    },
    userGreeting: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingVertical: 10,
    },
    userName: {
        fontSize: 26,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    dateTime: {
        fontSize: 18,
        color: '#555',
        marginTop: 5,
    }
});

const UserGreetingWidget = ({ name }) => {
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const germanDate = new Intl.DateTimeFormat('de-DE', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }).format(currentDate);

    return (
        <View style={styles.section}>
            <View style={styles.userGreeting}>
                <Text style={styles.userName}>Hallo {name}</Text>
                <Text style={styles.dateTime}>{germanDate}</Text>
            </View>
        </View>
    );
};

export default UserGreetingWidget;
