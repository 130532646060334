import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    appBar: {
        backgroundColor: '#f2f2f2',
        paddingVertical: 10,
        paddingHorizontal: 16,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        alignItems: 'center',
    },
    appBarTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
    },
});

const AppBar = () => {
    return (
        <View style={styles.appBar}>
            <Text style={styles.appBarTitle}>KreditDocs</Text>
        </View>
    );
};

export default AppBar;
