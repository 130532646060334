import React, {useContext, useEffect} from 'react';
import { ScrollView, View, Text, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import InfoWidget from "./InfoWidget";
import NotificationWidget from "./NotificationWidget";
import UserGreetingWidget from "../../UserGreetingWidget";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useUserPolling from "../../../hooks/useUserPolling";
import {UserContext} from "../../../context/UserContext";

const screenWidth = Dimensions.get("window").width;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
        backgroundColor: '#f4f4f4',
    },
    section: {
        marginBottom: 20,
        borderRadius: 12,
        padding: 15,
        backgroundColor: '#fff',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        elevation: 3,
    },
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 22,
        fontWeight: 'bold',
        color: '#444',
        marginBottom: 10,
    },
    subTitleText: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#444',
    },
    subTitleIcon: {
        marginRight: 10,
        borderTopColor: '#e0e0e0',
    },
    footer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderTopWidth: 1,
        borderTopColor: '#ddd',
        paddingTop: 20,
        paddingBottom: 10,
    },
    footerText: {
        fontSize: 16,
        color: '#007BFF',
        justifyContent : "center",
        textDecorationLine: 'underline'
    },
    notificationContainer: {
        flex: 1,
        borderRadius: 12,
        padding: 10,
        margin: 3,
        backgroundColor: '#FFFDE7',
        alignItems: 'center',
    },
    notificationText: {
        fontSize: 18,
        color: '#333',
        textAlign: 'center',
    },
    userGreeting: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    userName: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    dateTime: {
        fontSize: 16,
        color: '#666',
    }
});

const Home = () => {
    const { user, updateUser } = useContext(UserContext);

    useEffect(() => {
        updateUser();
    }, []);

    // const documentUploadCount = 120;
    // const userRegistrationComparison = 15;
    // const creditIncreaseCount = 80;

    // const widgets = [
    //     {
    //         icon: <FontAwesome5 name="user-plus" size={28} color="#333" />,
    //         title: "Kundenregistrierung",
    //         count: userRegistrationComparison,
    //         percentageIncrease: userRegistrationComparison,
    //         backgroundColor: "#E0F7FA",
    //         iconBackgroundColor: "#B2EBF2"
    //     },
    //     {
    //         icon: <FontAwesome5 name="file-upload" size={28} color="#333" />,
    //         title: "Documenthochladen",
    //         count: documentUploadCount,
    //         percentageIncrease: documentUploadCount,
    //         backgroundColor: "#FFE0B2",
    //         iconBackgroundColor: "#FFCC80"
    //     },
    //     {
    //         icon: <FontAwesome5 name="credit-card" size={28} color="#333" />,
    //         title: "Kreditanfrage",
    //         count: creditIncreaseCount,
    //         percentageIncrease: creditIncreaseCount,
    //         backgroundColor: "#E1BEE7",
    //         iconBackgroundColor: "#CE93D8"
    //     },
    // ];
    //
    // const notifications = [
    //     {
    //         uploaderName: "John Doe",
    //         uploadTime: "10:15 AM"
    //     },
    //     {
    //         uploaderName: "Alice",
    //         uploadTime: "9:45 AM"
    //     },
    //     {
    //         uploaderName: "John Doe",
    //         uploadTime: "10:15 AM"
    //     },
    // ];

    return (
        <ScrollView style={styles.container} contentContainerStyle={{ paddingBottom: 30 }}>

            {/* User Greeting Widget */}
            <UserGreetingWidget name= {user.firstName} />

            {/* Notification Section about document upload */}
            {/*<View style={styles.section}>*/}
            {/*    <View style={styles.subTitle}>*/}
            {/*        <FontAwesome5*/}
            {/*            name="bell"*/}
            {/*            size={24}*/}
            {/*            color="#444"*/}
            {/*            style={styles.subTitleIcon}*/}
            {/*        />*/}
            {/*        <Text style={styles.subTitleText}>Benachrichtigungen</Text>*/}
            {/*    </View>*/}

            {/*    <View style={styles.notificationContainer}>*/}
            {/*        {notifications.slice(0, 5).map((notification, index) => (*/}
            {/*            <NotificationWidget*/}
            {/*                key={index}*/}
            {/*                uploaderName={notification.uploaderName}*/}
            {/*                uploadTime={notification.uploadTime}*/}
            {/*            />*/}
            {/*        ))}*/}
            {/*    </View>*/}

            {/*    <TouchableOpacity onPress={() => {*/}
            {/*    }}>*/}
            {/*        <View style={styles.footer}>*/}
            {/*            <Text style={styles.footerText}>Alle Benachrichtigungen anzeigen</Text>*/}
            {/*        </View>*/}
            {/*    </TouchableOpacity>*/}
            {/*</View>*/}

            {/* Overview Section */}
            {/*<View style={styles.section}>*/}
            {/*    <View style={[styles.subTitle, { marginBottom: 20 }]}>*/}
            {/*    <FontAwesome5*/}
            {/*            name="chart-pie"*/}
            {/*            size={24}*/}
            {/*            color="#444"*/}
            {/*            style={styles.subTitleIcon}*/}
            {/*        />*/}
            {/*        <Text style={styles.subTitleText}>Overview</Text>*/}
            {/*    </View>*/}

            {/*    {widgets.map((widget, index) => (*/}
            {/*        <InfoWidget*/}
            {/*            key={index}*/}
            {/*            style={index === widgets.length - 1 ? { marginBottom: 0 } : {}}*/}
            {/*            icon={widget.icon}*/}
            {/*            title={widget.title}*/}
            {/*            count={widget.count}*/}
            {/*            percentageIncrease={widget.percentageIncrease}*/}
            {/*            backgroundColor={widget.backgroundColor}*/}
            {/*            iconBackgroundColor={widget.iconBackgroundColor}*/}
            {/*        />*/}
            {/*    ))}*/}
            {/*</View>*/}
        </ScrollView>
    );
};

export default Home;